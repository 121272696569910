import React from 'react';
import Container from '@material-ui/core/Container';
import ProductsTable from './components/ProductsTable';

import { useFetch } from 'use-http';
import { DataTableContextProvider } from '../../components/datatable/DataTable';

export default function ProductsPage() {

  const fetch = useFetch('/products', {
    perPage: 25,
    cachePolicy: 'no-cache'
  });

  return (
    <DataTableContextProvider value={fetch}>
      <Container maxWidth={false}>
        <ProductsTable />
      </Container>
    </DataTableContextProvider>
  );
}
