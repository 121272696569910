import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Controller } from 'react-hook-form';
import { useFetch } from 'use-http';
import { useDebounce } from 'use-debounce';

export default function ShipmentSelect({ name, control }) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState(null);
    const [debouncedSearch] = useDebounce(search, 400);

    const { request, response, loading } = useFetch('/shipments', {
        perPage: 10,
        cachePolicy: 'no-cache'
    });

    useEffect(() => {
        (async () => {
            const query = new URLSearchParams({ search: debouncedSearch });

            const shipments = await request.get(`?${query.toString()}`);

            if (response.ok) {
                setOptions(shipments.records.map(s => ({ name: s.reference, value: s.id })));
            }

        })();
    }, [debouncedSearch]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Controller
            as={
                <Autocomplete
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    onInputChange={(event, value, reason) => {
                        console.log('Input change', event, value, reason, search);
                        if (reason === 'input') {
                            setSearch(value);
                        }
                    }}
                    getOptionSelected={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => option.name}
                    options={options}
                    loading={loading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Shipment Reference"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />}
            control={control}
            defaultValue={null}
            onChange={([, data]) => data}
            name={name}
        />
    );
}