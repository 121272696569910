import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { useSnackbar } from '../../../context/snackbar';
import Paper from '@material-ui/core/Paper';
import { DataTable, useDataTable } from '../../../components/datatable/DataTable';
import StatusChip from '../../../components/StatusChip.js';
import { useFetch } from 'use-http';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export default function SwapsTable(props) {
  const snackbar = useSnackbar();
  const dataTable = useDataTable();
  const location = new URLSearchParams(useLocation().search);
  
  let columns = [{
      name: 'Swap ID',
      key: 'id',
      renderColumn: (record) => (<Link component={RouterLink} to={`/app/swap/${record.id}`}>{record.id}</Link>)

    },
    { 
      name: 'Status',
      key: 'status',
      renderColumn: (record) => (<StatusChip status={record.status} />)
    },
    { 
      name: 'Order ID',
      key: 'order_id'
    },
    { 
      name: 'Shopify Order ID',
      key: 'shopify_order_id'
    },
    { 
      name: 'Customer Shopify ID',
      key: 'shopify_customer_id'
    },
    { 
      name: 'Created At',
      key: 'created_at'
    },
    { 
      name: 'Updated At',
      key: 'updated_at'
    },
    { 
      name: 'Expires At',
      key: 'expires_at'
    }
  ];

  const fetch = useFetch('/swap-tokens', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const handleMultiFilterChange = (key) => {
    return (event, value) => {
      if (value && value.length > 0) {
        dataTable.setFilter(key, value[0].key);//value.map(v => v.key));
      } else {
        dataTable.setFilter(key, null);
      }
    }
  };

  function renderFilters() {

    const urlFilters = JSON.parse(location.get('filters')) || [];

    function getUrlFilters(key) {

      if (!urlFilters[key]) {
        return [];
      }

      if (!dataTable.data || !dataTable.data.filters || !dataTable.data.filters[key]) {
        return [];
      }

      return dataTable.data.filters[key].filter(i => urlFilters[key].includes(i.value));
    }

    return (
      dataTable &&
      dataTable.data.filters && (
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Autocomplete
            multiple
            limitTags={3}
            name="status"
            size="small"
            options={dataTable.data.filters.status || []}
            onChange={handleMultiFilterChange('status')}
            value={getUrlFilters('status')}
            getOptionLabel={(option) => (`${option.key} (${option.count})`)}
            renderInput={(params) => (
              <TextField {...params} name="status" variant="outlined" label="Status" placeholder="Select a status" />
            )}
          />
        </Grid>
      </Grid>)
    );
  }

  return (
    <Paper>
      <DataTable title="Swaps" showFilters filters={renderFilters()} columns={columns} />
    </Paper>
  );
}
