import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { DropzoneArea } from 'material-ui-dropzone';
import useIdVendor from '../helpers/IdVendor';
import useFetch from 'use-http';
import { Storage } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from '../context/snackbar';

const DEFAULT_MAX_FILE_SIZE = 134217728;

export default function FileUploadCard(props) {

  const history = useHistory();
  const [files, setFiles] = useState();
  const snackbar = useSnackbar();
  const { getId } = useIdVendor('document');
  const { getId: getFileID } = useIdVendor('tmp_object');
  const [uploadPercentage, setuploadPercentage] = useState(0);

  const { request, response } = useFetch('/documents', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const handleFileChange = (newFiles) => {
    console.log('Files:', newFiles);
    setFiles(newFiles);
  };

  const handleFileUpload = (options) => {

    return async (event) => {
      console.log('Upload files', files);

      for (const file of files) {
        const uploadResponse = await Storage.put(`upload/${await getFileID()}`, file, {
          progressCallback(progress) {
              console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
              var percent_complete = Math.round((progress.loaded / progress.total) * 100);
              setuploadPercentage(percent_complete);
        },
      });

        console.log('Upload response', uploadResponse);

        const newDocument = {
          id: await getId(),
          type: options.document_type,
          resource_id: options.resource_id,
          file_name: file.name,
          file_type: file.type,
          source: `public/${uploadResponse.key}`,
          size: file.size,
          last_modified: file.lastModifiedDate
        };

        console.log('Registering new document', newDocument, file);
        const docResponse = await request.post(newDocument);
        console.log('Doc create', docResponse);

        if (response.ok) {

          snackbar.success('Files uploaded successfully');
        } else {
          console.log('Problem uploading and registering the document');
        }
      }
    }
  };

  return (
    <Card>
      <CardContent>
        <DropzoneArea
          dropzoneText={props.title}
          showFileNames
          filesLimit={props.filesLimit}
          maxFileSize={props.maxFileSize || DEFAULT_MAX_FILE_SIZE}
          alertSnackbarProps={{anchorOrigin: { vertical: 'bottom', horizontal: 'right' }}}
          acceptedFiles={props.acceptedFiles}
          onChange={handleFileChange}
        />
        <br />
        <Button fullWidth={true} variant="contained" type="submit" onClick={handleFileUpload(props.metadata)}>
            {props.button_title || 'Upload'}
          </Button>
      </CardContent>
    </Card>
  );
}
