import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import TIUSelect from '../../../components/form/TIUSelect';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 160
  }
}));

export default function ProductDetailsForm({ handleImport }) {
  const classes = useStyles();
  const { control, register, errors } = useFormContext();

  return (
    <Grid container spacing={3}>

      <Grid item xs={12} md={6}>
        <input type="hidden" valuename="brand_id" value="brand_1oTj4Liq1KQkAtD0ucUnEmQ2xLs" />
        <input type="hidden" ref={register} name="factory_id" value="factory_1oTj65Ta7bqR9CCDnYaZKjJ1Z7V" />

        <TextField
          name="product_sku"
          label="Product SKU"
          inputRef={register({
            required: { value: true, message: 'Product SKU is required' },
            maxLength: { value: 10, message: 'Product SKU must be 10 characters' }
          })}
          error={!!errors.product_sku}
          helperText={errors.product_sku ? errors.product_sku.message : ''}
          required
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          name="product_code"
          label="Product Code"
          inputRef={register}
          error={!!errors.product_code}
          helperText={errors.product_code ? errors.product_code.message : ''}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          name="commodity_code"
          label="Commodity Code"
          inputRef={register}
          error={!!errors.commodity_code}
          helperText={errors.commodity_code ? errors.commodity_code.message : ''}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          type="number"
          name="shopify_product_id"
          label="Shopify Product ID"
          defaultValue={0}
          inputRef={register({
            valueAsNumber: true,
          })}
          error={!!errors.shopify_product_id}
          helperText={errors.shopify_product_id ? errors.shopify_product_id.message : ''}
          fullWidth
        />
        <Button variant="outlined" onClick={handleImport}>Import Shopify Variants</Button>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          name="name"
          label="Name"
          inputRef={register}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <TextField
          name="description"
          label="Description"
          inputRef={register}
          multiline
          rows={4}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TIUSelect
          required
          name="status"
          label="Status"
          options={[
            { value: 'DRAFT', label: 'Draft' },
            { value: 'ORDERED', label: 'Ordered' },
            { value: 'REJECTED', label: 'Rejected' },
            { value: 'SURPRISE', label: 'Surprise' }
          ]}
          control={control}
          errors={errors}
          rules={{ required: { value: true, message: 'A status must be selected' } }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          name="colour"
          label="Colour"
          inputRef={register}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TIUSelect
          required
          name="gender"
          label="Gender"
          defaultValue="female"
          options={[
            { value: 'female', label: 'Female', selected: true },
            { value: 'male', label: 'Male' },
            { value: 'girls', label: 'Girls' },
            { value: 'boys', label: 'Boys' },
            { value: 'unisex', label: 'Unisex' },
          ]}
          control={control}
          errors={errors}
          rules={{ required: { value: true, message: 'A tier must be selected' } }}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TIUSelect
          required
          name="tier"
          label="Tier"
          options={[
            { value: 'premium', label: 'Premium' },
            { value: 'midrange', label: 'Mid range' },
            { value: 'basic', label: 'Basic' },
          ]}
          control={control}
          errors={errors}
          rules={{ required: { value: true, message: 'A tier must be selected' } }}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TIUSelect
          required
          name="category"
          label="Category"
          options={[
            { value: 'dresses', label: 'Dress' },
            { value: 'tops', label: 'Top' },
            { value: 'sweatshirts', label: 'Sweatshirt' },
          ]}
          control={control}
          errors={errors}
          rules={{ required: { value: true, message: 'A category must be selected' } }}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TIUSelect
          name="subcategory"
          label="Subcategory"
          options={[
            { value: 'midi', label: 'Midi' },
            { value: 'mini', label: 'Mini' }
          ]}
          control={control}
          errors={errors}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TIUSelect
          name="pattern"
          label="Pattern"
          options={[
            { value: 'stripes', label: 'Stripes' }
          ]}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TIUSelect
          name="style"
          label="Style"
          options={[
            { value: 'midi', label: 'Midi' },
            { value: 'mini', label: 'Mini' }
          ]}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TIUSelect
          name="fit"
          label="Fit"
          options={[
            { value: 'loose', label: 'Loose' }
          ]}
          control={control}
          errors={errors}
        />
      </Grid>

    </Grid >
  );
}
