import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import useFetch from 'use-http';
import StockTable from './components/StockTable';
import FileUploadCard from '../../components/FileUploadCard';

export default function StockPage() {

  useEffect(() => {
    // getskuStatus();
  }, []);

  const { request: analyticsRequest } = useFetch('/analytics', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const getskuStatus = async () => {
    let statusResponse = await analyticsRequest.get('stock/sku_status');
    console.log("stock/sku_status", statusResponse);
  };

  let fileUploadProps = {
    upload_props : {
      documentType: 'fulfilment:warehouse_snapshot',
      taskType: 'fulfilment:stock-levels-process',
      metadata: {
        "BOX_SKU": "",
        "ASSIGNMENT_TYPE": "plan",
        "ASSIGNMENT_INDEX": "0",
        "BUILD_PLAN_INDEX": "0",
        "BUILD_WAVE": "0",
        "BATCH_SIZE": "0",
        "LIMIT_SKUS": "false",
        "RETURN_SKUS": "false"
      }
    },
    title : "Select a Stock Status file to upload",
    button_title : "Upload Stock Status"
  };

  return (
      <Container maxWidth={false}>
        Stock Page
        <FileUploadCard props={fileUploadProps}/>
        {/* <StockTable /> */}
      </Container>
  );
}
