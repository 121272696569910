import React from 'react';
import { makeStyles } from '@material-ui/styles';

import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    tableEmpty: {
        margin: theme.spacing(2, 0),
        padding: theme.spacing(4, 0),
        textAlign: 'center'
    }
}));

export default function TableEmpty(props) {
    const classes = useStyles();

    if (props.data && props.data.records && props.data.records.length > 0) {
        return null;
    }

    return (
        <Container className={classes.tableEmpty}>
            {props.message}
        </Container>
    );
}
