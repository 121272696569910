import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import useFetch from 'use-http';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  }
}));

export default function DashboardPage() {
  const classes = useStyles();
  const { request, response } = useFetch('notifications', {
    cachePolicy: 'no-cache'
  });

  async function handleSendNotification() {
    const notification = await request.post({
      status: 'CREATED',
      message: 'Sending email resting message',
      group_id: 'test-group',
      platform: 'email',
      data: {
        recipient: 'ross@thisisunfolded.com',
        recipient_name: 'Ross',
        subject: 'The journey begins...',
        template: 'step-1-fabric-resting'
      }
    });

    if (response.ok) {
      console.log('Notification sent', notification);
    } else {
      console.log('Notification failed', notification)
    }
  }


  async function handleSlackNotification() {
    const notification = await request.post({
      status: 'CREATED',
      message: 'Sending email resting message',
      group_id: 'test-slack',
      platform: 'slack',
      data: {
        channel: '#dev-fulfilment',
        messages: ['Test messsage',
          'from uf.notification.create',
          'Slack channel message'
        ]
      }
    });

    if (response.ok) {
      console.log('Notification sent', notification);
    } else {
      console.log('Notification failed', notification)
    }
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={6} lg={8}>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={6} lg={4}>
          {/* <Button variant="outlined" onClick={handleSendNotification} >Send email notification</Button> */}
        </Grid>
        {/* Recent Monitors */}
        <Grid item xs={12}>
          {/* <Button variant="outlined" onClick={handleSlackNotification} >Send slack notification</Button> */}
        </Grid>
      </Grid>
    </Container>
  );
}
