import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import theme from './theme';
import { AuthContextProvider } from './context/auth';
import { SnackbarContextProvider } from './context/snackbar';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <AuthContextProvider>
      <SnackbarContextProvider>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <App />
      </SnackbarContextProvider>
    </AuthContextProvider>
  </ThemeProvider>,
  document.querySelector('#root'),
);
