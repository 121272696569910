import React, { useState } from 'react';
import useFetch from 'use-http';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Can from '../../context/can';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import ContactsTable from './components/ContactsTable';
import ContactDialog from './components/ContactDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  titleAction: {
    textAlign: 'right'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function ContactsPage() {
  const classes = useStyles();

  const [showAddContact, setShowAddContact] = useState(false);
  const [idForEdit, setIDForEdit] = useState('');

  const {request: dataRequest} = useFetch("/contacts");

  const handleAddContact = () => {
    setShowAddContact(false);
  };
  
  const handleAdd = async () => {
    // const id = a8wait getId();
    setIDForEdit(null);
    setShowAddContact(true);
  };
  
  function handleEdit(id) {
    setIDForEdit(id);
    setShowAddContact(true);
  };
  
  const handleDelete = async (id) => {
    const deleteResponse = await dataRequest.delete(id);
    console.log("Delete Response", deleteResponse);
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.root}>
        <Grid container direction="row">
          <Grid item xs={8}>
            <Typography variant="h4">Contacts</Typography>
            {/* <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/" onClick={handleClick}>Collections</Link>
            </Breadcrumbs> */}
          </Grid>
          <Grid item xs={4} className={classes.titleAction}>
            <Can I="create" a="location">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={handleAdd}
              >New Contact</Button>
            </Can>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider}></Divider>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ContactsTable handleEdit={handleEdit} handleDelete={handleDelete}/>
          <ContactDialog open={showAddContact} onClose={handleAddContact} idForEdit={idForEdit}/>
        </Grid>
      </Grid>
    </Container>
  );
}
