import Chip from '@material-ui/core/Chip';

export default function StatusChip(props) {  

  let orange = '#e68231';
  let red = "#bf1f1c";
  let green = "#43a646";
  
  function chipFor(status) {

    var variant = "default";
    var color = orange;
    var textColor = "#FFFFFF"

    if (status == "PENDING") {
      variant = "outlined"
      color = "#FFFFFF"
      textColor = "#778e99"
    } else if (status == "COMPLETE" || status == "CREATED" || status == 'REDEEMED') {
      color = green
    } else if (status == "CANCELLED") {
      color = red
    }

    return ( 
    <Chip style={{backgroundColor: color, color: textColor}} label={status} variant={variant} />
    )
  }

  return (
    <>
    {chipFor(props.status)}
    </>
  );
}
