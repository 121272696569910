import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../context/snackbar';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import useFetch from 'use-http';
import EditForm from '../../../components/EditObjectForm';

export default function ShipmentPage(props) {
  const snackbar = useSnackbar();
  const location = new URLSearchParams(useLocation().search);
  const [shipmentID, setShipmentID] = useState("");

  useEffect(() => {
    setShipmentID(props.match.params.shipment_id);
  }, []);

  let form = {
    title : "Shipment",
    resource : "/shipments",
    query_slug : props.match.params.shipment_id,
    return_to : "/app/logistics",
    fields : [
      {
        key : "id",
        label: "ID",
        readOnly : true
      },
      {
        key : "reference",
        label : "Shipment Reference"
      },
      {
        key : "incoming_date",
        label : "Incoming Date"
      },
      {
        key : "updated_at",
        label : "Last Update"
      }
    ]
  };

  return (
    <Paper>
      <br />
      <EditForm props={form}/>
      <br />
    </Paper>
  );
}
