import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// Components
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import ProductDetailsForm from './components/ProductDetailsForm';
import ProductVariantsTable from './components/ProductVariantsTable';
import Image from 'material-ui-image';
import AddImagesButton from './components/AddImagesButton';

import ImgsViewer from 'react-images-viewer'

// Icons
import SaveIcon from '@material-ui/icons/Save';

// Hooks
import useIdVendor from '../../helpers/IdVendor';
import { useSnackbar } from '../../context/snackbar';
import useFetch from 'use-http';
import { FormProvider, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  titleAction: {
    textAlign: 'right'
  },
  imageViewer: {
    maxHeight: 300,
    height: '100%'
  },
  image: {
    width: '40%',
    // height: 'initial'
  }
}));

export default function ProductPage(props) {
  const classes = useStyles();
  const snackbar = useSnackbar();

  const { getId } = useIdVendor('product');

  const {
    product_id: productId
  } = useParams();

  const [product, setProduct] = useState();
  const { request, response } = useFetch(`/products/${productId}`);

  const form = useForm({
    mode: 'onBlur'
  });
  const productName = form.watch('name', 'Unnamed product');
  const { isDirty, isValid } = form.formState;

  const getProductId = async () => {
    product.id = await getId();
    console.log('New product id', product.id);
  }

  async function fetchProduct() {
    const product = await request.get();

    if (response.ok) {
      setProduct(product);
      form.reset(product);
      // form.triggerValidation();
      console.log('Form state', form.formState);
    } else {
      snackbar.error(`Failed to fetch product ${productId}`);
    }
  }

  function handleProductChange(product) {
    console.log('Setting product data', product);
    setProduct(product);
  }

  async function handleProductSave(product) {

    console.log('Form state submit', form.formState);
    console.log('Product', product);

    // PUT /products/id with whole product object
    await request.put(product);

    if (response.ok) {
      snackbar.success(`Product ${product.name} saved`);
    } else {
      snackbar.error(`Error saving product ${product.id}`);
    }
    // }
  }

  async function handleProductDelete(product) {
    await request.delete(product.id);

    if (response.ok) {
      snackbar.success(`Product ${product.name} was deleted`);
    } else {
      snackbar.error(`Error deleting product ${product.id}`)
    }
  }

  async function handleShopifyImport() {
    const product = form.getValues();
    console.log('Importing variants for product', product);
    await request.post(`shopify-import-variants`, {
      product_id: productId,
      product_sku: product.product_sku,
      shopify_product_id: product.shopify_product_id,

    });

    if (response.ok) {
      snackbar.success('Variants imported successfully');
    } else {
      snackbar.error('Error importing variants');
    }
  }

  const [imgVwrOpen, setImgVwrOpen] = useState(false);
  const [currentImg, setCurrentImg] = useState(0);
  const closeImgsViewer = () => { setImgVwrOpen(false); }
  const gotoNextImg = () => { setCurrentImg(currentImg + 1); }
  const gotoPrevImg = () => { setCurrentImg(currentImg - 1); }
  const clickImage = (index) => {
    if (!imgVwrOpen) {
      setImgVwrOpen(true);
      setCurrentImg(index);
    }
  }

  const images = () => {
    var images = [];

    for (var key in product.images) {
      const img = { src : product.images[key].url};
      images.push(img);
    }
    
    return images;
  }
  
  function renderImage(product) {
    
    const image = product.images[Object.keys(product.images)[0]];

    if (!image) {
      return (<span>NA</span>);
    }

    return (
      <div className={classes.root}>
        <Grid container>
          {images().map((image, i) => (
            <Grid item xs={3}>
              <img src={image.src} className={classes.image} onClick={() => clickImage(i)}  />
            </Grid>
          ))}
        </Grid>

        <AddImagesButton product={product} />
        <ImgsViewer
          imgs={images()}
          isOpen={imgVwrOpen}
          currImg={currentImg}
          onClickPrev={gotoPrevImg}
          onClickNext={gotoNextImg}
          onClose={closeImgsViewer}
          backdropCloseable={true}
          // To add custom buttons >> customControls={[imgButtton()]}
        />
      </div>
    );
  }

  function imgButtton() {
    return (
      <Button
            variant="contained"
            color="primary"
            >
            Send to Shopify
        </Button>
    );
  }
  
  useEffect(() => {
    if (props.mode === 'CREATE') {
      getProductId();
    } else {
      fetchProduct();
    }
  }, []);

  return (
    <Container maxWidth="lg" className={classes.container}>
      {product ? (
        <div className={classes.root}>
          <Grid container direction="row">
            <Grid item xs={8}>
              <Typography variant="h4">{productName}</Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/" component={RouterLink} to="/app/products">Products</Link>
                <Typography color="textPrimary">{productName}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={4} className={classes.titleAction}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<SaveIcon />}
                // disabled={!form.formState.isDirty || !form.formState.isValid}
                onClick={form.handleSubmit(handleProductSave)}>
                Save
            </Button>
            </Grid>
          </Grid>
          <Divider className={classes.divider}></Divider>
          <br/>
          <Grid container direction="row">
            {/* <Grid item xs={12} md={4}> */}
              {renderImage(product)}

            {/* </Grid> */}
          </Grid>
          <br/>
          {/* <Grid container>
          <Grid item xs={12} md={8}> */}
              <FormProvider {...form}>
                <form>
                  <Paper>
                    <Typography variant="h6">Product details</Typography>
                    <ProductDetailsForm handleImport={handleShopifyImport}/>
                  </Paper>
                </form>
              </FormProvider>
            {/* </Grid>
          </Grid>  */}

          <br/>
          {/* <Paper> */}
          <ProductVariantsTable
            productId={productId}
            productSku={product.product_sku}
            shopifyProductId={product.shopify_product_id}
          />
          {/* </Paper> */}
        </div>
      ) : (<Skeleton variant="text" />)}
    </Container >
  );
}
