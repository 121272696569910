import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import useFetch from 'use-http';
import Divider from '@material-ui/core/Divider';
import { LazyLog } from 'react-lazylog';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function FulfilmentTaskLogPage({ match }) {
  const taskId = match.params.task_id;
  const classes = useStyles();

  const { request, response } = useFetch('/documents', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const { request: fulfilmentRequest } = useFetch('/fulfilment', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const [logText, setlogText] = useState(" ");
  const [detailText, setdetailText] = useState(" ");
  const [status, setstatus] = useState(" ");
  let timer = null;

  useEffect(() => {
    fetchDetails()
    fetchLogs()
    return () => clearTimeout(timer);
  }, []);

  const fetchDetails = async () => {
     // GET /fulfilment/tasks/{task_id} To get details on task that ran
    const detailsResponse = await fulfilmentRequest.get("/tasks/" + taskId)
    setdetailText(JSON.stringify(detailsResponse, null, 2))

    // Get the status of this task
    let newStatus = detailsResponse.status
    setstatus(newStatus)

    // if status == 'RUNNING' setup timer to poll the logs api 
    if (newStatus == 'RUNNING' || newStatus == 'STARTED') {
      fetchLogs()
      timer = setTimeout(() => {
        fetchDetails()
      }, 30000);
    } else {
      // fetch log one last time to get the full log and stop timer
      fetchLogs()
      clearTimeout(timer);
      timer = null
    }
  };

  const fetchLogs = async (taskID) => {
    // GET /fulfilment/tasks/logs/{task_id} To get the logs of the task
   const logResponse = await fulfilmentRequest.get("/tasks/logs/" + taskId)
   appendLogs(logResponse);
 };

 const appendLogs = (logs) => {
   let tempLog = ""
   console.log(logs);
   if (logs.logs != null) {
     logs.logs.map((log) => (
      tempLog += log.message + '\n'
    ))
    setlogText(tempLog)
   }
 };

  return (
    <Container>
          <Card>

            Task: {taskId} 
            <br></br>
            Status: {status} - logs of 'STARTED' or 'RUNNING' tasks will refresh every 30 seconds
          
            <Divider />
        Task Details:
        <div style={{ height: 400 }}>
          <LazyLog extraLines={1} text={detailText} caseInsensitive enableSearch selectableLines />
        </div>

        <Divider />
        Task Logs:
        <div style={{ height: 400 }}>
          <LazyLog extraLines={1} text={logText} caseInsensitive enableSearch selectableLines follow />
        </div>

        </Card>
    </Container>
  );
}