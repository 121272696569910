import React from 'react';
import { Route, Switch, useHistory } from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useFetch from 'use-http';

import Can from '../../context/can';
import { DataTableContextProvider } from '../../components/datatable/DataTable';
import TrackingPage from './TrackingPage';

export default function TrackingRouter({ match }) {

  const [tab, setTab] = React.useState(0);
  const history = useHistory();

  const fetch = useFetch('/documents', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const handleChange = (event, newTab) => {
    console.log('New tab', newTab);
    setTab(newTab);
    history.push(`tracking/${newTab}`);
  };

  return (
    <Can I="view" a="tracking">
      <DataTableContextProvider value={fetch}>
        <AppBar position="static">
          <Tabs value={tab} onChange={handleChange} aria-label="Tracking tabs">
            <Tab label="Overview" value="overview" />
            <Tab label="Files" value="files" />
            <Tab label="Activity" value="activity" />
          </Tabs>
        </AppBar>
        <Switch>
          <Route path={`${match.url}/overview`} component={TrackingPage} />
          <Route path={`${match.url}/files`} component={TrackingPage} />
          <Route path={`${match.url}/activity`} component={TrackingPage} />
        </Switch>
      </DataTableContextProvider>
    </Can>
  );
}