import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    errorPanel: {
        margin: theme.spacing(4)
    },
    title: {
        margin: theme.spacing(2, 2)
    },
    error: {
        margin: theme.spacing(2, 2)
    },
    message: {
        margin: theme.spacing(2, 2)
    }
}));

export default function TableError(props) {
    const classes = useStyles();
    return (
        <div className={classes.errorPanel}>
            {props.error && (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.title}>
                            Something went wrong. Try refreshing...
                        </Typography>
                    </AccordionSummary>
                    { props.response && (
                    <AccordionDetails>
                        <Grid layout="column">
                            <Grid item>
                                <Typography className={classes.error}>
                                    {props.response.error}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.message}>
                                    {props.response.message}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button onClick={props.retry}>Retry</Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>)}
                </Accordion>
            )}
        </div>
    );
}
