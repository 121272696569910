import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function StatsTable({ props, rows }) {

  const { request: statsRequest }= useFetch('/analytics/' + props.resource + '/' + props.query_slug);
  const [stats, setStats] = useState({});

  useEffect(() => {
    getStats();
  }, []);

  const getStats = async () => {
    const statsResponse = await statsRequest.get();
    if (statsResponse.data) {
      setStats(statsResponse.data[0]);
    }
  };

  const classes = useStyles();

  return (
    <Container>
          <Card>
          <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Stats</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
          {rows.map((row) => (
              <TableRow key={row.key}>
                <TableCell component="th" scope="row">
                  {row.label}
                </TableCell>
                <TableCell align="right">
                  {stats[row.key]} 
                </TableCell>
              </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
        </Card>
    </Container>
  );
}