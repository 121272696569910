import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../context/snackbar';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import AddIcon from '@material-ui/icons/Add';
import Can from '../../../context/can';
import useIdVendor from '../../../helpers/IdVendor';
import { DataTable, useDataTable, DataTableContextProvider } from '../../../components/datatable/DataTable';
import PurchaseOrderDialog from './PurchaseOrderDialog';

import { useFetch } from 'use-http';

export default function PurchaseOrdersTable(props) {
  const snackbar = useSnackbar();
  const dataTable = useDataTable();
  const location = new URLSearchParams(useLocation().search);


  const fetch = useFetch('/purchase-orders', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const [showAddPurchaseOrder, setShowAddPurchaseOrder] = useState(false);

  let columns = [{
    name: 'Reference',
    key: 'reference',
    renderColumn: (record) => (<Link component={RouterLink} to={`/app/buying/purchase_orders/${record.id}`}>{record.reference}</Link>)
  }, {
    name: 'Supplier',
    key: 'supplier.name'
  }, {
    name: 'Factory',
    key: 'factory.name'
  }, {
    name: 'Last Update',
    key: 'updated_at',
    sortable: true
  }];

  // const { request, response } = dataTable;


  const handleAdd = async () => {
    // const id = a8wait getId();
    setShowAddPurchaseOrder(true);
  };

  const handleAddPurchaseOrder = () => {
    setShowAddPurchaseOrder(false);
  };

  function renderToolbar() {
    return (
      <Can I="create" a="purchase_order">
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={handleAdd}
        >Add Purchase Order</Button>
      </Can>
    );
  }

  return (
    <Paper>
      <DataTableContextProvider value={fetch}>
        <DataTable title="Purchase Orders" columns={columns} toolbar={renderToolbar()} />
        <PurchaseOrderDialog open={showAddPurchaseOrder} onClose={handleAddPurchaseOrder} />
      </DataTableContextProvider>
    </Paper>
  );
}
