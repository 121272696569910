import React from 'react';
import { Route, Switch } from "react-router-dom";
import Can from '../../context/can';
import FulfilmentPage from './FulfilmentPage';
import FulfilmentTaskLogPage from './FulfilmentTaskLogPage'

export default function FulfilmentRouter({ match }) {

  console.log('Fulfilfment match',  match.url);
  return (
    // <Can I="view" a="fulfilment">
      <Switch>
        <Route path={`${match.url}/:task_id`} component={FulfilmentTaskLogPage} />
        <Route path={`${match.url}/add`} component={FulfilmentPage} />
        <Route path={`${match.url}/`} component={FulfilmentPage} />
      </Switch>
    // </Can>
  );
}