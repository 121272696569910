import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DropzoneArea } from 'material-ui-dropzone';

import { Controller, useForm } from 'react-hook-form';
import { useDataTable } from '../../../components/datatable/DataTable';
import useIdVendor from '../../../helpers/IdVendor';
import useFetch from 'use-http';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 160
  }
}));

export default function ProductDialog(props) {
  const classes = useStyles();
  const { register, handleSubmit, reset, control, errors } = useForm();
  const { request, response } = useFetch();
  const { getId } = useIdVendor('product');
  const { getId: getProductImageId } = useIdVendor('product_image');

  const [files, setFiles] = useState([]);

  const handleClose = (event) => {
    props.onClose();
  };

  const onSubmit = async (data) => {
    const id = await getId();

    const product = await request.post('/products', { id, ...data });

    if (response.ok) {
      await handleFileUpload(product);
      reset();
      props.onClose(product);
    }
  };

  const handleFileChange = (newFiles) => {
    console.log('Files:', newFiles);
    setFiles(newFiles);
  };

  const handleFileUpload = async (product) => {
    console.log('Upload files', files);

    for (const file of files) {
      const newImage = {
        id: await getProductImageId(),
        content_type: file.type
      };

      const taskResponse = await request.post(`/products/${product.id}/images`, newImage);
      console.log('Task response', taskResponse);
      const imageUploadResponse = await fetch(taskResponse.url, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
          'x-amz-meta-id': newImage.id,
          'x-amz-meta-product_id': product.id,
        },
        body: file
      });
      console.log('Image response', imageUploadResponse);
    }
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Product</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add a new product
          </DialogContentText>
          <form onSubmit={handleSubmit(onSubmit)}>

            <TextField
              inputRef={register}
              autoFocus
              margin="dense"
              name="product_sku"
              label="SKU"
              type="string"
              fullWidth
            />

            <TextField
              inputRef={register}
              autoFocus
              margin="dense"
              name="name"
              label="Name"
              type="string"
              fullWidth
            />

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="category">Category</InputLabel>
              <Controller
                as={
                  <Select labelId="category" label="Category">
                    <MenuItem value="dresses" selected>Dresses</MenuItem>
                    <MenuItem value="tops">Tops</MenuItem>
                  </Select>
                }
                name="category"
                control={control}
              />
              <FormHelperText>
                {errors.type && <span>This field is required</span>}
              </FormHelperText>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="tier">Tier</InputLabel>
              <Controller
                as={
                  <Select labelId="tier" label="Tier">
                    <MenuItem value="premimum" selected>Premium</MenuItem>
                    <MenuItem value="midrange">Mid Range</MenuItem>
                    <MenuItem value="basic">Basic</MenuItem>
                  </Select>
                }
                name="tier"
                control={control}
              />
              <FormHelperText>
                {errors.type && <span>This field is required</span>}
              </FormHelperText>
            </FormControl>

            <DropzoneArea
              dropzoneText="Select an image file to upload"
              showFileNames
              filesLimit={10}
              alertSnackbarProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }}
              acceptedFiles={['image/*']}
              onChange={handleFileChange}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}