import React, { useState } from 'react';
import useFetch from 'use-http';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Can from '../../context/can';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {Link } from "react-router-dom";
import OrdersTable from './components/OrdersTable';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  titleAction: {
    textAlign: 'right'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function OrdersPage() {
  const classes = useStyles();
  

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.root}>
        <Grid container direction="row">
          <Grid item xs={8}>
            <Typography variant="h4">Orders</Typography>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider}></Divider>
      <Grid container spacing={3}>
        <Grid item xs={12}> 
          <OrdersTable/>
        </Grid>
      </Grid>
    </Container>
  );
}
