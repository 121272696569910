import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { useForm, Controller } from 'react-hook-form';
import { useDataTable } from '../../../components/datatable/DataTable';
import useIdVendor from '../../../helpers/IdVendor';
import useFetch from 'use-http';

export default function CollectionDialog(props) {
  const { register, handleSubmit, reset, control, errors } = useForm();
  const { getId } = useIdVendor('collection');
  const { request, response } = useFetch('/collections');
  const history = useHistory();

  const handleClose = (event) => {
    props.onClose();
  };

  const onSubmit = async (data) => {
    const id = await getId();

    const newCollection = await request.post({ id, products: [], images: [], ...data });

    if (response.ok) {
      reset();
      props.onClose(data);
      history.push({
        pathname: `/app/collections/${newCollection.id}`
      });
    }
  };

  return (
    <div>

      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create new collection</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A collection is the range of products our customers can select from to purchase as part of their Unfolded Edit.
          </DialogContentText>
          <form onSubmit={handleSubmit(onSubmit)}>

            <TextField
              inputRef={register}
              autoFocus
              margin="dense"
              name="name"
              label="Collection name"
              type="string"
              fullWidth
            />

            <FormControl variant="outlined">
              <InputLabel htmlFor="type">Department</InputLabel>
              <Controller
                as={
                  <Select labelId="type" label="Department">
                    <MenuItem value="womens" selected>Womens</MenuItem>
                    <MenuItem value="mens">Mens</MenuItem>
                    <MenuItem value="girls">Girls</MenuItem>
                    <MenuItem value="boys">Boys</MenuItem>
                  </Select>
                }
                name="department"
                defaultValue="womens"
                control={control}
              />
              <FormHelperText>
                {errors.type && <span>This field is required</span>}
              </FormHelperText>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}