import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../context/snackbar';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import Can from '../../../context/can';
import useIdVendor from '../../../helpers/IdVendor';
import { DataTable, useDataTable, DataTableContextProvider } from '../../../components/datatable/DataTable';
import CollectionDialog from './CollectionDialog';

import { useFetch } from 'use-http';

export default function CollectionsTable(props) {
  const snackbar = useSnackbar();
  const dataTable = useDataTable();
  const location = new URLSearchParams(useLocation().search);


  const fetch = useFetch('/collections', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  function deleteCollection(collection) {
    return (event) => {
      fetch.request.delete(collection.id);
      if (fetch.response.ok) {
        snackbar.success(`Collection ${collection.name} deleted`);
      }
    };
  }

  let columns = [{
    name: 'Name',
    key: 'reference',
    renderColumn: (record) => (<Link component={RouterLink} to={`/app/collections/${record.id}`}>{record.name}</Link>)
  }, {
    name: 'Status',
    key: 'status'
  }, {
    name: 'Last update',
    key: 'updated_at',
    sortable: true
  }, {
    name: 'Actions',
    renderColumn: (record) => (deleteButton(record))

  }];

  function deleteButton(collection) {
    return (
      <Button
        key={collection.id}
        variant="contained"
        color="secondary"
        endIcon={(<DeleteIcon />)}
        onClick={deleteCollection(collection)}
      >Delete</Button>
    );
  }

  return (
    <Paper>
      <DataTableContextProvider value={fetch}>
        <DataTable title="Collections" columns={columns} />
      </DataTableContextProvider>
    </Paper>
  );
}
