import React from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import TrackingFilesTable from './TrackingFilesTable';

export default function TrackingFilesPanel() {

    return (

        <TrackingFilesTable />

    )
}