import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import { useDataTable } from '../../../components/datatable/DataTable';
import { useSnackbar } from '../../../context/snackbar';

export default function ProductsActionMenu({ open, anchorEl }) {

  const snackbar = useSnackbar();
  const dataTable = useDataTable();

  const handleRaisePurchaseOrder = (products) => {
    console.log('Raise PO with products', products, dataTable.selection);
  };

  const handleAddPurchaseOrder = (products) => {
    console.log('Add to purchase handleRaisePurchaseOrder', products);
  };

  const handleMarkUnavailable = (products) => {
    console.log('Mark unavailable', products);
  };

  const handleDelete = async (products) => {
    console.log('Handle delete', products, dataTable.selected);
    await Promise.all(mockDelete(dataTable.selected));
    console.log('Finished deleting');
    snackbar.success(`Deleted ${dataTable.selected.length} products`);
  };

  const mockDelete = (records) => {
    return records.map(record => {
      return dataTable.request.delete(`/${record.id}`);
    });
  };

  return (
    <div>
      <MenuItem onClick={handleRaisePurchaseOrder}>Raise PO</MenuItem>
      <MenuItem onClick={handleAddPurchaseOrder}>Add to PO</MenuItem>
      <MenuItem onClick={handleMarkUnavailable}>Mark Unavailable</MenuItem>
      <MenuItem onClick={handleDelete}>Delete</MenuItem>
    </div>
  );
}