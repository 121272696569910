import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';

import AddIcon from '@material-ui/icons/Add';
import { Storage } from 'aws-amplify';
import useFetch from 'use-http';
import useIdVendor from '../../helpers/IdVendor';

import { render } from 'react-dom';
import { LazyLog } from 'react-lazylog';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Link as RouterLink } from 'react-router-dom';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import StatsTable from './StatsTable';
import JobTable from './JobTable';
import BuildManifestFilesTable from './BuildManifestFilesTable'
import FileUploadCard from '../../components/FileUploadCard';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function FulfilmentPage({ match }) {
  const history = useHistory();
  const location = new URLSearchParams(useLocation().search);

  const classes = useStyles();
  const { getId } = useIdVendor('document');
  const { getId: getFulfilmentId } = useIdVendor('fulfilment_task');
  const { getId: getFileID } = useIdVendor('tmp_object');

  useEffect(() => {
    getManifestFiles();
  }, []);

  const { request, response } = useFetch('/documents', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const { request: fulfilmentRequest } = useFetch('/fulfilment', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const { request: generateManifestRequest } = useFetch('/analytics/stock/sku_manifest?export=true');
  const manifestFilesFetch = useFetch(`/documents?filter={"type":["export:build-manifest"]}&sort={"created_at":"desc"}`, {});
  const [manifestFiles, setManifestFiles] = useState([]);

  const getManifestFiles = async () => {
    let docsResult = await manifestFilesFetch.get();
    setManifestFiles(docsResult.records);
    console.log("Docs", docsResult.records.slice(0, 5));
  };

  const [uploadPercentage, setuploadPercentage] = useState(0);
  const [tasks, setTasks] = useState([]);

  const handleTriggerTask = (options) => {

    return async (event) => {
      const task = {
        id: await getFulfilmentId(),
        type: options.taskType,
        metadata: Object.assign({}, options.metadata || {})
      }

      const taskResponse = await fulfilmentRequest.post('/tasks/run', task);
      console.log('Task response', taskResponse);
      goToLog(task.id);
    }
  };

  const goToLog = (taskID) => history.push(`/app/fulfilment/${taskID}`);

  const [selectedTaskType, setselectedTaskType] = useState("");
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    setselectedTaskType(event.target.value);
    fetchList(event.target.value)
  };

  const fetchList = async (taskType) => {
    // POST /fulfilment/tasks/list <JSON Body { "type": "shopify:tagger" } > To list tasks by their type
    const typeBody = {
      type: taskType
    }
    const listResponse = await fulfilmentRequest.post('/tasks/list', typeBody)
    console.log("Tasks", listResponse);
    setTasks(listResponse.records)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  function progressCircle() {
    return (
      <div style={{ width: 60, height: 60 }}>
        <CircularProgressbar value={uploadPercentage} text={`${uploadPercentage}%`} />
      </div>
    );
  };

  const handleDownloadTask = (options) => {

    return async (event) => {
      const task = {
        type: options.taskType,
        metadata: Object.assign({}, options.metadata || {})
      }

      const taskResponse = await fulfilmentRequest.get(`${options.taskType}/download`);

      const download = await fetch(taskResponse.URL);
      console.log('Task response', taskResponse);
      console.log('Download', download);

      var file = window.URL.createObjectURL(download.blob());
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `file`);
      document.body.appendChild(link);
      link.click();
    }
  }

  const triggerBuildManifestFileGeneration = async () => {
    const triggerResult = await generateManifestRequest.get();
    console.log("Trigger result", triggerResult);

    await timeout(60000); // wait 1 minute
    getManifestFiles();
  };

  const sendTrackingNumbers = async () => {

  };

  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }

  let statsRows = [
    {
      label: "New",
      key: "boxes_new"
    },
    {
      label: "Planned",
      key: "boxes_planned"
    },
    {
      label: "In Build",
      key: "boxes_in_build"
    },
    {
      label: "Shipped",
      key: "boxes_shipped"
    }
  ];

  let fileUploadProps = {
    upload_props: {
      documentType: 'fulfilment:build_status',
      taskType: 'import:boxstatus'
    },
    title: "Select a Build Status file to upload",
    button_title: "Upload Build Status"
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <StatsTable props={{ resource: "fulfilment", query_slug: "stats" }} rows={statsRows} />
          <br />
          <Container>
            <Button variant="contained" type="submit" onClick={handleTriggerTask({
              taskType: 'export:build-manifest',
              metadata: {
                S3_BUCKET: 'ls-api-documents-store'
              }
            })}>
              Generate Build Manifest</Button>
            <Button variant="contained" type="submit" onClick={handleTriggerTask({
              taskType: 'shopify:fulfilment',
              metadata: {
                SHOPIFY_SHOP: 'mallzee',
                TASK_TYPE: 'warehouse'
              }
            })}>
              Send Tracking Numbers</Button>
          </Container>
        </Grid>
        <Grid item xs={6}>
          <FileUploadCard props={fileUploadProps} />
        </Grid>
        <Grid item xs={12}>
          <BuildManifestFilesTable files={manifestFiles} />
        </Grid>
      </Grid>
      <br /><br />
      {/* <Card>
          <CardContent>
            <DropzoneArea
              dropzoneText="Select a fulfilment file to upload"
              showFileNames
              filesLimit={1}
              maxFileSize={128000000}
              alertSnackbarProps={{anchorOrigin: { vertical: 'bottom', horizontal: 'right' }}}
              acceptedFiles={['.csv', '.xlsx']}
              onChange={handleFileChange}
            />
          </CardContent>
          <CardActions>
            <Button type="submit" onClick={handleFileUpload({
              documentType: 'fulfilment:build_status',
              taskType: 'import:boxstatus'
            })}>Build Status</Button>
            <Button type="submit" onClick={handleFileUpload({
              documentType: 'stock:warehouse_snapshot',
              taskType: 'import:stock-levels'
            })}>Stock Status</Button>
            <Button type="submit" onClick={handleFileUpload({
              documentType: 'shopify:fulfilment_updates',
              taskType: 'shopify:fulfilment',
              metadata: {
                SHOPIFY_SHOP: 'mallzee',
                TASK_TYPE: 'csv'
              }
            })}>Fulfilment</Button>
            <Button type="submit" onClick={handleFileUpload({
              documentType: 'shopify:order_tags',
              taskType: 'shopify:tagger',
              metadata: {
                SHOPIFY_SHOP: 'mallzee'
              }
            })}>Order Tags</Button>
            <Button type="submit" onClick={handleTriggerTask({
              taskType: 'shopify:fulfilment',
              metadata: {
                SHOPIFY_SHOP: 'mallzee',
                TASK_TYPE: 'warehouse'
              }
            })}>SQL Fulfilment</Button>
            <Button type="submit" onClick={handleFileUpload({
              documentType: 'stock:customs_inbound',
              taskType: 'import:customs-inbound',
              metadata: {
                PO_REFERENCE: ' 1353'
              }
            })}>Customs Inbound</Button>
            <Button type="submit" onClick={handleDownloadTask({
              taskType: 'manifests',
            })}>Download Manifest</Button>
          </CardActions>
        File Upload Progress:
        {progressCircle()}
        <br></br>
        </Card> */}
      <br></br>
      <JobTable />
      <br></br>
      {/* <Card>
        Choose a task type below to see details of recently performed tasks
        <InputLabel>Task type</InputLabel>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={selectedTaskType}
          onChange={handleChange}
        >
          <MenuItem value="import:build-plan">Build Plan Status</MenuItem>
          <MenuItem value="assignments:assign">Assignment Status</MenuItem>
          <MenuItem value="import:boxstatus">Build Status</MenuItem>
          <MenuItem value='import:stock-levels'>Stock Status</MenuItem>
          <MenuItem value='shopify:fulfilment'>Fulfilment</MenuItem>
          <MenuItem value='shopify:tagger'>Order Tags</MenuItem>
        </Select>

        <List>
          {tasks.map((task) => (
            <ListItem
            button
            component={RouterLink} to={`/app/fulfilment/${task.id}`}
            key={task.id}>
              <ListItemText
                primary={task.id}
              />
            </ListItem>
          ))}

        </List>
      </Card> */}
    </Container>
  );
}