import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import ProductCard from './ProductCard';

import { useFetch } from 'use-http';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    media: {
        height: '100%',
        paddingTop: '56.25%', // 16:9
    },
    warning: {
        color: theme.palette.warning.main
    }
}));

export default function ProductsGrid({ filters, productMenuOptions, productIDs}) {

    const classes = useStyles();

    const [products, setProducts] = useState({ records: [] });
    const { request, response } = useFetch(`/products?size=100&sort={"created_at":"asc"}&filter={"ids":["${productIDs()}"]}`, {
        perPage: 100,
        cachePolicy: 'no-cache'
    });

    async function fetchProducts() {
        const products = await request.get();
        setProducts(products);
    }

    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                {products.records.map(product => (
                    <Grid item xs={12} sm={4} lg={3}>
                        <ProductCard product={product} menuOptions={productMenuOptions} />
                    </Grid>
                ))}
            </Grid>

        </Box>
    );
}