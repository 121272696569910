import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { useDataTable } from '../../../components/datatable/DataTable';
import Title from '../../../components/Title';
import { Grid } from '@material-ui/core';
import { Controller, useForm, useWatch } from 'react-hook-form';
import useFetch from 'use-http';
import useIdVendor from '../../../helpers/IdVendor';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  formControl: {
    minWidth: 160
  }
});

export default function PlanningControlPanel() {
  const classes = useStyles();
  const tasklist = useDataTable();
  const { getId: getFulfilmentId } = useIdVendor('fulfilment_task');

  const initialMetadata = {
    S3_BUCKET: 'ls-api-documents-store',
    ASSIGNMENT_TYPE: "planned",
    ASSIGNMENT_INDEX: "0",
    BUILD_PLAN_INDEX: "0",
    BUILD_WAVE: "0",
    BOX_SKU: '',
    BATCH_SIZE: "0",
    LIMIT_SKUS: false,
    RETURN_SKUS: false
  };

  const [metadata, setMetadata] = useState(initialMetadata);
  const { register, control, errors, handleSubmit } = useForm({ defaultValues: metadata });

  const { request: fulfilmentRequest } = useFetch('/fulfilment', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const onSubmit = async (data) => {

    data.LIMIT_SKUS = data.LIMIT_SKUS ? "true" : "false";
    data.RETURN_SKUS = data.RETURN_SKUS ? "true" : "false";

    const assignmentTask = {
      id: await getFulfilmentId(),
      type: 'assignments:assign',
      metadata: data
    }

    console.log('Starting assignment', assignmentTask, metadata);
    const taskResponse = await fulfilmentRequest.post('/tasks/run', assignmentTask);
  };

  return (
    <Paper>
      <Title>Planning Assignment</Title>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="S3_BUCKET" ref={register} />
          <input type="hidden" name="ASSIGNMENT_INDEX" ref={register} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="type">Assignment type</InputLabel>
                <Controller
                  as={
                    <Select labelId="type" label="Assignment type">
                      <MenuItem value="">-- Select Type --</MenuItem>
                      <MenuItem value="planned">Planned</MenuItem>
                      <MenuItem value="assigned">Assigned</MenuItem>
                    </Select>
                  }
                  name="ASSIGNMENT_TYPE"
                  defaultValue=""
                  control={control}
                />
                <FormHelperText>
                  {errors.type && <span>This field is required</span>}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register}
                autoFocus
                margin="dense"
                name="BUILD_PLAN_INDEX"
                label="Build plan index"
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register}
                autoFocus
                margin="dense"
                name="BUILD_WAVE"
                label="Build wave"
                type="number"
                fullWidth
              />

            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register}
                autoFocus
                margin="dense"
                name="BATCH_SIZE"
                label="Batch size"
                type="number"
                fullWidth
              />

            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="type">Box SKU</InputLabel>
                <Controller
                  as={
                    <Select labelId="type" label="Box SKU">
                      <MenuItem value="">All</MenuItem>
                      <MenuItem value="lostbox1">SS20</MenuItem>
                      <MenuItem value="loststock-aw20">AW20</MenuItem>
                      <MenuItem value="lskids1">Kids</MenuItem>
                    </Select>
                  }
                  name="BOX_SKU"
                  defaultValue=""
                  control={control}
                />
                <FormHelperText>
                  {errors.type && <span>This field is required</span>}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {metadata.RETURN_SKUS}
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">SKU operations</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    name="LIMIT_SKUS"
                    control={<Checkbox />}
                    label="Limit SKUs"
                    inputRef={register}
                  />
                  <FormControlLabel
                    name="RETURN_SKUS"
                    control={<Checkbox />}
                    label="Return SKUs"
                    inputRef={register}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit">Start Assignment</Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Paper>
  )
}