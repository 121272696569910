import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';
import { useFetch } from 'use-http';

// Icons
import CompleteIcon from '@material-ui/icons/Done';
import PreperationIcon from '@material-ui/icons/Timer';
import { Snackbar } from '@material-ui/core';
// import CuttingIcon from '@material-ui/icons/Cut';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  successTail: {
    backgroundColor: theme.palette.success.main,
  },
  primaryTail: {
    backgroundColor: theme.palette.primary.main,
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  standardTail: {
  },
  step: {
    marginBottom: theme.spacing(2)
  }
}));

export default function CollectionProductionTimeline({ collection, onChange }) {
  const classes = useStyles();
  const { request, response } = useFetch(`/collections/${collection.id}`);

  function handleCompleteStep(step) {
    return async () => {
      const newCollection = await request.put('/step', {
        step: step.step,
        status: step.data.status === 'in_progress' ? 'complete' : 'in_progress'
      });

      if (response.ok) {
        onChange(newCollection);
      } else {
        console.log(response.error);
      }
    };
  }

  function handleNotifyStep(step) {
    return async () => {
      const newCollection = await request.put('/step', {
        step: step.step,
        status: step.data.status,
        notified: true,
        notified_at: (new Date()).toISOString()
      });


      if (response.ok) {
        onChange(newCollection);
      } else {
        console.log(response.error);
      }
    };
  }


  console.log('Collection steps', collection.steps);
  const productionSteps = [
    {
      title: 'In design',
      description: 'Collection is being designed based on the data guidelines given by product team',
      icon: (<PreperationIcon />),
      step: 'in_design',
      data: (collection.steps && collection.steps.in_design) || {}
    },
    {
      title: 'In test',
      step: 'in_test',
      description: 'Collection is being tested on the creator group to narrow down the collection selection to the products we want to produce',
      icon: (<PreperationIcon />),
      data: (collection.steps && collection.steps.in_test) || {}
    },
    {
      title: 'In review',
      step: 'in_review',
      description: 'Collection is being reviewed before placing the order with the factory',
      icon: (<PreperationIcon />),
      data: (collection.steps && collection.steps.in_review) || {}
    },
    {
      title: "Sourcing materials",
      step: 'sourcing',
      description: "Upload",
      icon: (<PreperationIcon />),
      component: (<Button />),
      data: (collection.steps && collection.steps.sourcing) || {}
    },
    {
      title: "Material preperation",
      step: 'resting',
      optional: true,
      description: "The period when material is laid out to rest before cutting. Continue when this phase is complete",
      component: (<Button />),
      data: (collection.steps && collection.steps.resting) || {}
    },
    {
      title: "Cutting",
      step: 'cutting',
      optional: true,
      description: "Upload the product designs and create the set of products to be evaluated for this collection",
      component: (<Button>Create</Button>),
      data: (collection.steps && collection.steps.cutting) || {}
    },
    {
      title: "Stitching",
      step: 'stitching',
      optional: true,
      description: "Select the products from design for testing",
      component: (<Button />),
      data: (collection.steps && collection.steps.stitching) || {}
    },
    {
      title: "Finishing",
      step: 'finishing',
      optional: true,
      description: "After reviewing the product test results. Select the final set of products to be placed",
      component: (<Button />),
      data: (collection.steps && collection.steps.finishing) || {}
    },
    {
      title: "Kitting",
      step: 'kitting',
      optional: true,
      description: "Items are combinded together to create the customer orders",
      component: (<Button />),
      data: (collection.steps && collection.steps.kitting) || {}
    },
    {
      title: "Transporting",
      step: 'transporting',
      optional: true,
      description: "The goods are on the way to the UK and will be ready for the final mile delivery once they clear customs",
      component: (<Button />),
      data: (collection.steps && collection.steps.transporting) || {}
    }
  ];

  function renderNextStepButton(step) {
    console.log('Render complete', step.step, step.data)

    if (step.data.status !== 'in_progress' && !step.data.notified) {

      return (
        <Button variant="contained" color="primary" onClick={handleCompleteStep(step)}>
          Start step
        </Button>
      );
    }

    if (
      (step.data.status === 'in_progress')
      && !step.data.notified
    ) {
      return (
        <Button variant="contained" color="primary" onClick={handleNotifyStep(step)}>
          Notify users
        </Button>
      );
    }

    if ((step.data.status === 'in_progress') && step.data.notified) {
      return (
        <Button variant="contained" color="primary" onClick={handleCompleteStep(step)}>
          Complete step
        </Button>
      );
    }
  }

  function renderTimelineItem (step, index) {

    return step.data && (
      <TimelineItem key={index}>
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              {step.data.started_at && (<span>Started: <Moment fromNow>{step.data.started_at}</Moment></span>)}
              <br />
              {step.data.completed_at && (<span>Completed: <Moment fromNow>{step.data.completed_at}</Moment></span>)}
              <br />
              {step.data.notified_at && (<span>Notified: <Moment fromNow>{step.data.notified_at}</Moment></span>)}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={classes.successTail} color={step.data.status === 'complete' ? 'inherit' : step.data.status === 'in_progress' ? 'secondary' : ''}>
              {step.data.status === 'complete' ? (<CompleteIcon />) : step.icon}
            </TimelineDot>
            <TimelineConnector className={step.data.status === 'complete' ? classes.successTail : step.data.status === 'in_progress' ? classes.secondaryTail : classes.standardTail} />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <div className={classes.step}>
                <Typography variant="h6" component="h1">
                  {step.title}
                </Typography>
                <Typography>{step.description}</Typography>
              </div>
              {renderNextStepButton(step)}
            </Paper>
          </TimelineContent>
        </TimelineItem>
    )
  }

  return (
    <Timeline align="alternate">
      { productionSteps.map(renderTimelineItem) }
    </Timeline>
  );
}
