import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useForm, Controller } from 'react-hook-form';
import useFetch from 'use-http';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '../../../context/snackbar';

export default function PostageURLDialog(props) {

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();
  const { register, handleSubmit, reset, control, errors } = useForm();
  const [url, setURL] = useState();
  const snackbar = useSnackbar();

  const {
    swap_id: swapId
  } = useParams();

  const {request: dataRequest, response} = useFetch(`/swap-tokens/${swapId}/postage`);

  function _Change(event){
    setURL(event.target.value);
  }

  const handleClose = (event) => {
    props.onClose();
  };

  const onSubmit = async (data) => {

      const updateResponse = await dataRequest.put({"carrier" : "royal_mail", "url" : url});

      if (response.ok) {
        props.onClose(data);
      } else {
        snackbar.error(`Failed to update postage url`);
      }
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Postage URL</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the URL for the Postage Information
          </DialogContentText>
          { layoutTextFields() }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  function layoutTextFields() {
    return(
      <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              inputRef={register}
              // autoFocus
              margin="dense"
              name="name"
              label="URL"
              type="string"
              fullWidth
              onChange={_Change}
            />
          </form>
    )
  }
}