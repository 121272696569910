import React from 'react';
import { Route, Switch } from "react-router-dom";
import Can from '../../context/can';
import BuyingPage from './BuyingPage';
import PurchaseOrderPage from './PurchaseOrderPage';

export default function BuyingRouter({ match }) {
  return (
    <Can I="view" a="fulfilment">
      <Switch>
        <Route path={`${match.url}/purchase_orders/:po_id`} render={(props) => (<PurchaseOrderPage {...props} mode="update" />)} />
        <Route path={`${match.url}/`} component={BuyingPage} />
        <Route path={`${match.url}/add`} component={BuyingPage} />
      </Switch>
    </Can>
  );
}