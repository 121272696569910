import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

export default function AddressForm(props) {  


  return (
    <>
    <Typography variant="subtitle1">{props.title}</Typography>

          <TextField
            inputRef={props.register}
            margin="dense"
            name="name"
            label="Name"
            type="string"
            fullWidth
            onChange={props._ChangeAddress}
            defaultValue={props.address.name || ''}
          />

          <TextField
            inputRef={props.register}
            margin="dense"
            name="address1"
            label="Address Line 1"
            type="string"
            fullWidth
            onChange={props._ChangeAddress}
            defaultValue={props.address.address1 || ''}
          />

          <TextField
            inputRef={props.register}
            margin="dense"
            name="address2"
            label="Address Line 2"
            type="string"
            fullWidth
            onChange={props._ChangeAddress}
            defaultValue={props.address.address2 || ''}
          />

          <TextField
            inputRef={props.register}
            margin="dense"
            name="city"
            label="City"
            type="string"
            fullWidth
            onChange={props._ChangeAddress}
            defaultValue={props.address.city || ''}
          />

          <TextField
            inputRef={props.register}
            margin="dense"
            name="province"
            label="County"
            type="string"
            fullWidth
            onChange={props._ChangeAddress}
            defaultValue={props.address.province || ''}
          />

          <TextField
            inputRef={props.register}
            margin="dense"
            name="zip"
            label="Post Code"
            type="string"
            fullWidth
            onChange={props._ChangeAddress}
            defaultValue={props.address.zip || ''}
          />

          <TextField
            inputRef={props.register}
            margin="dense"
            name="country"
            label="Country"
            type="string"
            fullWidth
            onChange={props._ChangeAddress}
            defaultValue={props.address.country || ''}
          />

          <TextField
            inputRef={props.register}
            margin="dense"
            name="phone"
            label="Phone Number"
            type="string"
            fullWidth
            onChange={props._ChangeAddress}
            defaultValue={props.address.phone || ''}
          />

          {props.geolocation &&
          <TextField
            inputRef={props.register}
            margin="dense"
            name="lng"
            label="GPS Longitude"
            type="string"
            fullWidth
            onChange={props._ChangeGeo}
            defaultValue={props.geolocation.lng || ''}
          />
          }

          {props.geolocation &&
          <TextField
            inputRef={props.register}
            margin="dense"
            name="lat"
            label="GPS Latitude"
            type="string"
            fullWidth
            onChange={props._ChangeGeo}
            defaultValue={props.geolocation.lat || ''}
          />
          }
          </>
            
  );
}
