import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DropzoneArea } from 'material-ui-dropzone';

import { Controller, useForm } from 'react-hook-form';
import { useDataTable } from '../../../components/datatable/DataTable';
import useIdVendor from '../../../helpers/IdVendor';
import useFetch from 'use-http';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 160
  }
}));

export default function ProductInventoryDialog({ open, onClose, inventory }) {
  const classes = useStyles();

  const { register, handleSubmit, reset, errors } = useForm({
      defaultValues: inventory
  });

  function handleClose() {
    onClose();
  }

  async function onSubmit(data) {
    console.log('Submit', inventory, data);

    try {
        await onClose({ ...data, ...inventory });

    } catch (error) {
        console.log('On close error', error);
    }

    reset();
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add inventory</DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>
            Adjust the quantity of stock available for this variant
          </DialogContentText>

            <TextField
              type="number"
              name="quantity"
              label="Quantity"
              inputRef={register({
                  valueAsNumber: true,
                  required: { value: true, message: 'A quantity is required' }
              })}
              error={!!errors.quantity}
              helperText={errors.quantity ? errors.quantity.message : ''}
              required
              autoFocus
              fullWidth
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)} color="primary">
            Add
          </Button>
        </DialogActions>
          </form>
      </Dialog>
    </div>
  );
}