import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Container from '@material-ui/core/Container';
import { DropzoneArea } from 'material-ui-dropzone';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';

import AddIcon from '@material-ui/icons/Add';
import { Storage } from 'aws-amplify';
import useFetch from 'use-http';
import useIdVendor from '../../helpers/IdVendor';
import PurchaseOrdersTable from './components/PurchaseOrdersTable';


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function FulfilmentPage({ match }) {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={6} lg={8}>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={6} lg={4}>
        </Grid>
        {/* Recent Monitors */}
        <Grid item xs={12}>
          <PurchaseOrdersTable />
        </Grid>
      </Grid>
    </Container>
  );
}
