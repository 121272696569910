import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DropzoneArea } from 'material-ui-dropzone';

import { useForm } from 'react-hook-form';
import useIdVendor from '../../../helpers/IdVendor';
import useFetch from 'use-http';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 160
  }
}));

export default function ImageUploadDialog(props) {
  const classes = useStyles();
  const { register, handleSubmit, reset, control, errors } = useForm();
  const { request, response } = useFetch();
  const { getId } = useIdVendor('product');
  const { getId: getProductImageId } = useIdVendor('product_image');

  const [files, setFiles] = useState([]);

  const handleClose = (event) => {
    props.onClose();
  };

  const onSubmit = async (data) => {

    console.log(props);
    // const id = await getId();

    // const product = await request.post('/products', { id, ...data });

    // if (response.ok) {
      await handleFileUpload(props.product);
      reset();
      props.onClose(props.product);
    // }
  };

  const handleFileChange = (newFiles) => {
    console.log('Files:', newFiles);
    setFiles(newFiles);
  };

  const handleFileUpload = async (product) => {
    console.log('Upload files', files);

    for (const file of files) {
      const newImage = {
        id: await getProductImageId(),
        content_type: file.type
      };

      const taskResponse = await request.post(`/products/${product.id}/images`, newImage);
      console.log('Task response', taskResponse);
      const imageUploadResponse = await fetch(taskResponse.url, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
          'x-amz-meta-id': newImage.id,
          'x-amz-meta-product_id': product.id,
        },
        body: file
      });
      console.log('Image response', imageUploadResponse);
    }
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Product Image</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add new image to product
          </DialogContentText>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DropzoneArea
              dropzoneText="Select an image file to upload"
              showFileNames
              filesLimit={10}
              alertSnackbarProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }}
              acceptedFiles={['image/*']}
              onChange={handleFileChange}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}