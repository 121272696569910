import React from 'react';
import {
  Route, Switch
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

import { mainListItems } from '../menuItems';

// Routers
import DashboardRouter from '../pages/dashboard/DashboardRouter';
import CollectionsRouter from '../pages/collections/CollectionsRouter';
import BuyingRouter from '../pages/buying/BuyingRouter';
import LocationsRouter from '../pages/locations/LocationsRouter';
import LogisticsRouter from '../pages/logistics/LogisticsRouter';
import PlanningRouter from '../pages/planning/PlanningRouter';
import ProductsRouter from '../pages/products/ProductsRouter';
import FulfilmentRouter from '../pages/fulfilment/FulfilmentRouter';
import TrackingRouter from '../pages/tracking/TrackingRouter';
import DocumentsRouter from '../pages/documents/DocumentsRouter';
import StockRouter from '../pages/stock/StockRouter';
import ContactsRouter from '../pages/contacts/ContactsRouter';
import OrdersRouter from '../pages/orders/OrdersRouter';
import SwapRouter from '../pages/swap/SwapRouter';

// Components
import AccountMenu from '../components/account/AccountMenu';
import Copyright from '../components/Copyright';

import { ReactComponent as Logo } from '../icons/unfolded-logo-white.svg';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  logo: {
    width: 130
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
  },
  title: {
    flex: 1,
    paddingLeft: theme.spacing(2)
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function Main({ match }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Logo className={classes.logo} />
          <Typography variant="h6" className={classes.title}></Typography>
          <AccountMenu className={classes.menuButton} />
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Box className={classes.container} >
          <Switch>
            <Route path={`${match.url}/buying`} component={BuyingRouter} />
            <Route path={`${match.url}/collections`} component={CollectionsRouter} />
            <Route path={`${match.url}/dashboard`} component={DashboardRouter} />
            <Route path={`${match.url}/fulfilment`} component={FulfilmentRouter} />
            <Route path={`${match.url}/logistics`} component={LogisticsRouter} />
            <Route path={`${match.url}/planning`} component={PlanningRouter} />
            <Route path={`${match.url}/products`} component={ProductsRouter} />
            <Route path={`${match.url}/tracking`} component={TrackingRouter} />
            <Route path={`${match.url}/documents`} component={DocumentsRouter} />
            <Route path={`${match.url}/stock`} component={StockRouter} />
            <Route path={`${match.url}/locations`} component={LocationsRouter} />
            <Route path={`${match.url}/contacts`} component={ContactsRouter} />
            <Route path={`${match.url}/orders`} component={OrdersRouter} />
            <Route path={`${match.url}/swap`} component={SwapRouter} />
            <Route path={`${match.url}/`} component={DashboardRouter} />
          </Switch>
        </Box>
        <Box pt={4}>
          <Copyright />
        </Box>
      </main>
    </div>
  );
}
