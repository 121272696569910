import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Card, CardContent, Grid } from '@material-ui/core';

export default function EditSubForm({props, formKey, onChange}) {

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '90%',
    },
    variant_textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 50,
    },
    variant_sku_textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 120,
    },
    button: {
      marginRight: theme.spacing(1),
      float: "right"
    }
  }));

  const classes = useStyles();
  const [object, setObject] = useState({});

  useEffect(() => {
    setObject(props);
  }, []);

  

  function _Change(event){
    let objectCopy = object;
    objectCopy[formKey][event.target.name] = event.target.value;
    setObject(objectCopy);

    if (onChange) {
      onChange(objectCopy);
    }
  }

  return (
    <Grid item xs={6}>
    <Card>
      <CardContent>
        {formKey}
        <br/>
        {Object.keys(props[formKey]).map((field) => (
          <TextField
          name={field}
          label={field}
          id={formKey + "_" + field}
          key={formKey + "_" + field}
          defaultValue={props[formKey][field] || ''} 
          className={classes.textField}
          onChange={_Change}
          />
        ))}
      </CardContent>
    </Card>
  </Grid>
  );
}