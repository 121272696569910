import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { isNil, set } from 'lodash';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    marginRight: theme.spacing(1),
    float: "right"
  },
}));

export default function VariantModal(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [variant, setVariant] = useState({});
  const [size, setSize] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [sku, setSku] = useState(props.product.product_sku);
  let sizes = [
    {
      value: '01',
      label: 'XS',
    },
    {
      value: '02',
      label: 'S',
    },
    {
      value: '03',
      label: 'M',
    },
    {
      value: '04',
      label: 'L',
    },
    {
      value: '05',
      label: 'XL',
    },
    {
      value: '06',
      label: 'XXL',
    },
    {
      value: '20',
      label: '4-5 YEARS',
    },
    {
      value: '21',
      label: '5-6 YEARS',
    },
    {
      value: '22',
      label: '6-7 YEARS',
    },
    {
      value: '23',
      label: '7-8 YEARS',
    },
    {
      value: '24',
      label: '8-9 YEARS',
    },
    {
      value: '25',
      label: '9-10 YEARS',
    },
    {
      value: '26',
      label: '10-11 YEARS',
    },
    {
      value: '27',
      label: '11-12 YEARS',
    },
    {
      value: '28',
      label: '12-13 YEARS',
    },
    {
      value: '29',
      label: '13-14 YEARS',
    }
  ];
  let currencies = [
    {
      value: 'USD',
      label: '$',
    },
    {
      value: 'GBP',
      label: '£',
    }
  ];

  const handleOpen = () => {
    setOpen(true);
    if (props.variantToUpdate != null) {
      setVariant(props.variantToUpdate);
      setCurrency(props.variantToUpdate.unit_cost.currency);
      setSku(props.variantToUpdate.variant_sku);
    } else {
      setSku(props.product.product_sku);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    if (props.onChange) {
      props.onChange(variant);
    }
    setOpen(false);
  }

  const isEditing = () => {
    return props.variantToUpdate;
  }

  function _Change(event) {

    let variantCopy = variant;

    if (event.target.name == "unit_cost") {
      variantCopy[event.target.name] = unitCostObjectFor(event.target.value);
    } else if (event.target.name == "units_available" ||
      event.target.name == "units_bought") {
      variantCopy[event.target.name] = parseInt(event.target.value);
    } else {
      variantCopy[event.target.name] = event.target.value;
    }

    setVariant(variantCopy);
  };

  function _ChangeSize(event) {
    setSize(event.target.value);
    setSku(props.product.product_sku + event.target.value);

    let variantCopy = variant;
    variantCopy[event.target.name] = sizeStringfor(event.target.value);
    variantCopy["sku"] = props.product.product_sku + event.target.value;
    setVariant(variantCopy);
  };

  function _ChangeCurrency(event) {

    if (isEditing()) {
      props.variantToUpdate.unit_cost.currency = event.target.value
    }
    setCurrency(event.target.value);
    let variantCopy = variant;
    let unitCostCopy = variantCopy.unit_cost ? variantCopy.unit_cost : {};
    unitCostCopy.currency = event.target.value;
    variantCopy.unit_cost = unitCostCopy;
    setVariant(variantCopy);
  };

  function sizeStringfor(value) {
    let size = sizes.find(o => o.size === value);
    return size.size;
  }

  function valueForSize(string) {
    let size = sizes.find(s => s.size === string.toUpperCase());

    if (!size) {
      return 'N/A';
    }

    return size.size;
  }

  function unitCostObjectFor(cost) {
    return (
      {
        value: parseFloat(cost) * 100,
        currency: (currency)
      }
    )
  };

  function formattedUnitCostfor(cost) {
    return (
      parseInt(cost, 10) / 100
    );
  };

  //   available: true
  // created_at: "2020-08-20T21:00:56.568532933Z"
  // id: "f6c59054-5d08-4bb5-b3a4-eff81df61f41"
  // name: "Brod Frill Corset - White"
  // product_sku: "101030011"
  // purchased: true
  // size: "l"
  // unit_cost: {value: 585, currency: "GBP"}
  // unit_height: {value: 0, unit: ""}
  // unit_length: {value: 0, unit: ""}
  // unit_weight: {value: 0, unit: ""}
  // unit_width: {value: 0, unit: ""}
  // units_available: 222
  // units_bought: 222
  // updated_at: "2020-08-20T21:00:56.568532933Z"
  // variant_sku: "10103001104"

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">{isEditing() ? "Update Variant" : "Add a new Variant"}</h2>

      <TextField
        id="size"
        name="size"
        select
        label="Size"
        value={isEditing() ? valueForSize(props.variantToUpdate.size) : size}
        onChange={_ChangeSize}
        helperText="Please select a size"
        className={classes.textField}
      >
        {sizes.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          label="SKU"
          id="sku"
          className={classes.textField}
          name='sku'
          value={sku}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Available"
          id="quantity"
          className={classes.textField}
          onChange={_Change}
          name='quantity'
          defaultValue={isEditing() ? props.variantToUpdate.units_available : ''}
        />
        <br />
        <TextField
          id="currency"
          name="currency"
          select
          label="Currency"
          value={currency}
          onChange={_ChangeCurrency}
          className={classes.textField}
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <br />
        <TextField
          label="Unit Cost"
          id="unit_cost"
          placeholder="eg 5.85"
          className={classes.textField}
          onChange={_Change}
          name='unit_cost'
          defaultValue={isEditing() ? formattedUnitCostfor(props.variantToUpdate.unit_cost.value) : ''}
        />
      </form>
      <br />
      <Button
        variant="contained"
        onClick={handleClose}
        className={classes.button}>
        Cancel
      </Button>
      <Button
        variant="contained"
        onClick={save}
        className={classes.button}>
        Save
      </Button>
    </div>
  );

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleOpen}
        className={classes.button}>
        {isEditing() ? "Edit" : "Add New Variant"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
