import React, { useState } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { DropzoneArea } from 'material-ui-dropzone';
import { Storage } from 'aws-amplify';
import useFetch from 'use-http';
import useIdVendor from '../../../helpers/IdVendor';

export default function TrackingUploadPanel() {
  const { getId } = useIdVendor('document');
  const { getId: getFulfilmentId } = useIdVendor('fulfilment_task');

  const { request } = useFetch('/documents', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const { request: fulfilmentRequest } = useFetch('/fulfilment', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const [files, setFiles] = useState();

  const handleFileChange = (newFiles) => {
    console.log('Files:', newFiles);
    setFiles(newFiles);
  };

  const handleFileUpload = async (event) => {
    console.log('Upload files', files);

    for (const file of files) {
      // const uploadResponse = await Storage.put(`upload/${file.name}`, file);

      // console.log('Upload response', uploadResponse);

      // const newDocument = {
      //   id: await getId(),
      //   type: 'order_update',
      //   file_name: file.name,
      //   file_type: file.type,
      //   document_type: 'fulfilment:address_change',
      //   document_type: 'stock:snapshot',
      //   source: `upload/${file.name}`,
      //   size: file.size,
      //   last_modified: file.lastModifiedDate
      // };

      // const docResponse = await request.post(newDocument);
      // console.log('Doc create', docResponse, document);

      const initialAssignmentTask = {
        id: await getFulfilmentId(),
        type: 'assignments:assign',
        metadata: {
          S3_BUCKET: 'ls-api-documents-store',
          ASSIGNMENT_TYPE: "planned",
          ASSIGNMENT_INDEX: "0",
          BUILD_PLAN_INDEX: "14",
          BUILD_WAVE: "0",
          BOX_SKU: 'lskids1',
          BATCH_SIZE: "0"
        }
      }

      const assignmentTask = {
        id: await getFulfilmentId(),
        type: 'assignments:assign',
        metadata: {
          S3_BUCKET: 'ls-api-documents-store',
          ASSIGNMENT_TYPE: "planned",
          ASSIGNMENT_INDEX: "0",
          BUILD_PLAN_INDEX: "10",
          BUILD_WAVE: "0",
          BOX_SKU: 'lostbox1',
          BATCH_SIZE: "0"
        }
      }

      const buildStatusTask = {
        id: await getFulfilmentId(),
        type: 'import:boxstatus',
        metadata: {
          S3_BUCKET: 'ls-api-documents-store',
          S3_KEY: 'fulfilment/build_status/status update 18 09 2020.xlsx'
        }
      }

      const stockStatusTask = {
        id: await getFulfilmentId(),
        type: 'import:stock-levels',
        metadata: {
          S3_BUCKET: 'ls-api-documents-store',
          S3_KEY: 'stock/库存表-0911.xlsx'
        }
      }

      const fulfilmentTask = {
        id: await getFulfilmentId(),
        type: 'shopify:fulfilment',
        metadata: {
          S3_BUCKET: 'ls-api-documents-store',
          S3_KEY: 'fulfilment/builds/src_fulfilment_manifest_build_plan_5_manifest_6__2020_09_22.csv',
          SHOPIFY_SHOP: 'mallzee'
        }
      }

      const addressChangeTask = {
        id: await getFulfilmentId(),
        type: 'shopify:addresscheck',
        metadata: {
          S3_BUCKET: 'ls-api-documents-store',
          S3_KEY: 'fulfilment/address_changes/address_change_mistake_unmodified.csv',
          SHOPIFY_SHOP: 'mallzee'
        }
      }

      const taskResponse = await fulfilmentRequest.post('/tasks/run', initialAssignmentTask);
      // const taskResponse = await fulfilmentRequest.post('/tasks/run', assignmentTask);
      // const taskResponse = await fulfilmentRequest.post('/tasks/run', buildStatusTask);
      // const taskResponse = await fulfilmentRequest.post('/tasks/run', stockStatusTask);
      // const taskResponse = await fulfilmentRequest.post('/tasks/run', fulfilmentTask);
      // const taskResponse = await fulfilmentRequest.post('/tasks/run', addressChangeTask);

      console.log('Task response', taskResponse);
    }
  };

  return (
    <Card>
    <CardContent>
      <DropzoneArea
        dropzoneText="Select a tracking file to upload"
        showFileNames
        filesLimit={1}
        alertSnackbarProps={{anchorOrigin: { vertical: 'bottom', horizontal: 'right' }}}
        acceptedFiles={['.csv']}
        onChange={handleFileChange}
      />
    </CardContent>
    <CardActions>
      <Button type="submit" onClick={handleFileUpload}>Submit</Button>
    </CardActions>
  </Card>

  )
}