import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { useSnackbar } from '../../../context/snackbar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { DataTable, useDataTable, DataTableContextProvider } from '../../../components/datatable/DataTable';
import StatusChip from '../../../components/StatusChip.js';

import { useFetch } from 'use-http';

export default function NotificationsTable(props) {
  const snackbar = useSnackbar();
  const dataTable = useDataTable();
  const location = new URLSearchParams(useLocation().search);

  const fetch = useFetch(`/notifications?group_id=${props.orderId}`, {
    perPage: 10,
    cachePolicy: 'no-cache'
  });
  
  let columns = [{
      name: 'Message',
      key: 'message'
    },
    { 
      name: 'Platform',
      key: 'platform'
    },
    { 
      name: 'Status',
      key: 'status',
      renderColumn: (record) => (<StatusChip status={record.status} />)
    },
    {
      name: 'Notification ID',
      key: 'id',
    },
    { 
      name: 'Group ID',
      key: 'group_id'
    },
    { 
      name: 'Created At',
      key: 'created_at'
    }
  ];

  return (
    <Paper>
      <DataTableContextProvider value={fetch}>
        <DataTable title="Notifications" columns={columns} />
      </DataTableContextProvider>
    </Paper>
  );
}
