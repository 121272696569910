import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Card, CardContent, Grid } from '@material-ui/core';
import AddressForm from './EditAddressForm';

export default function EditForm(props) {

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '90%',
    },
    variant_textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 50,
    },
    variant_sku_textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 120,
    },
    button: {
      marginRight: theme.spacing(1),
      float: "right"
    }
  }));

  const classes = useStyles();
  const { request: dataRequest } = useFetch(props.props.resource);
  const [object, setObject] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    console.log("Props", props.props);
    fetchData()
  }, []);

  const fetchData = async () => {
    const response = await dataRequest.get(props.props.query_slug);
    console.log("Response", response);
    setObject(response);
    setLoaded(true);
  }

  function _Change(event){
    let objectCopy = object;
    objectCopy[event.target.name] = event.target.value;
    setObject(objectCopy);

    // console.log("New Object", objectCopy);
  }

  const _subFormChange = (newObject) => {
    // console.log("NewObject", newObject);
    setObject(newObject);
  };

  const saveObject = async () => {
    // PUT /resource/id with whole object
    const updatetResponse = await dataRequest.put(object.id, object);
    // setProduct(productResponse);
    console.log("Update Response", updatetResponse);
  };

  return (
      <Container maxWidth={false}>
        <LayoutTextFields/>
        <br/><br/>
        <Grid container spacing={3}>
          {props.props.address_forms != null &&
            props.props.address_forms.map((field) => (
              loaded &&
              subForm(field)
            ))
          }
        </Grid>
      </Container>
  );

  function subForm(formKey) {
    return(
      <AddressForm props={object} formKey={formKey} onChange={_subFormChange} />
    );
  }

  function LayoutTextFields() {
    const classes = useStyles();

    return (
      <Container>
        <Button 
            variant="contained" 
            component={RouterLink} to={props.props.return_to}
            className={classes.button}>
              Cancel
          </Button>
          <Button 
            variant="contained" 
            className={classes.button}
            onClick={() => { saveObject() }}>
              Save
          </Button>
        <Typography>Edit {props.props.title}</Typography>
        {props.props.fields.map((field) => (
            <TextField
            name={field.key}
            label={field.label}
            id={field.key}
            key={field.key}
            defaultValue={object[field.key] || ''} 
            className={classes.textField}
            onChange={_Change}
            InputProps={{
              readOnly: field.readOnly || false,
            }}
          />
        ))}
    </Container>
    );
  }
}