import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import StatusChip from '../../../components/StatusChip.js';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  titleAction: {
    textAlign: 'right'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function VariantCard(props) {

  const variantDisplay = [
    {
      "label" : "ID",
      "key" : "id"
    },
    {
      "label" : "Order ID",
      "key" : "orderID"
    },
    {
      "label" : "Collection ID",
      "key" : "collectionID"
    },
    {
      "label" : "SKU",
      "key" : "sku"
    },
    {
      "label" : "Shopify Customer ID",
      "key" : "shopifyCustomerID"
    },
    {
      "label" : "Shopify ID",
      "key" : "shopifyID"
    },
    {
      "label" : "Shopify Order ID",
      "key" : "shopifyOrderID"
    },
    {
      "label" : "Shopify Product ID",
      "key" : "shopifyProductID"
    }
  ]

  const randomDisplay = [
    {
      "label" : "Random",
      "key" : "isRandom"
    },
    {
      "label" : "Random SKU",
      "key" : "randomSKU"
    }
  ]

  const classes = useStyles();
  const [buttonTitle, setButtonTitle] = useState("SHOW MORE");
  const [showingMore, setShowingMore] = useState(true);

  const handleShowMore = () => {
    if (showingMore) {
      setButtonTitle("SHOW LESS");
    } else {
      setButtonTitle("SHOW MORE");
    }

    setShowingMore(!showingMore);
  }

  function variantCard(variant, index) {
    var adjustedIndex = parseInt(index);
    adjustedIndex = adjustedIndex + 1;
    return (
      <>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {"Variant " + adjustedIndex }
              </Typography>
              {variantDisplay.map((item) => (
                <>
                <Grid container>
                  <Grid item xs={3}>
                    <Typography variant="body2" component="p">
                      {item.label + ":" }
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" component="p">
                      {variant[item.key]}
                    </Typography>
                  </Grid>
                </Grid>
                </>
              ))}
              {variant.isRandom == "Yes" && randomDetails(variant)}
              <Divider className={classes.divider}></Divider>
              {statusRow()}
              <Divider className={classes.divider}></Divider>
              <Button
                variant="outlined"
                // color="secondary"
                onClick={handleShowMore}>
                  {buttonTitle}
              </Button>
              {!showingMore && more()}
            </CardContent>
          </Card>
          <br />
          </>
    )
  }

  function randomDetails(variant) {
    return (
      <>
      {randomDisplay.map((item) => (
        <>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant="body2" component="p">
              {item.label + ":" }
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" component="p">
              {variant[item.key]}
            </Typography>
          </Grid>
        </Grid>
        </>
      ))}
      </>
    )
  }

  function more() {
    return (
      <>
      <br /><br />
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        Production
      </Typography>
      {stepRow()}
      {stepRow("sourcing")}
      {stepRow("resting")}
      {stepRow("cutting")}
      {stepRow("stitching")}
      {stepRow("finishing")}
      {stepRow("kitting")}
      {stepRow("transporting")}
      </>
    )
  }

  function statusRow() {
    return (
      <>
      <Grid container>
        <Grid container style={{width: "15%"}}>
          <Grid item xs={6}>
            <Typography variant="body2" component="p">
              Status:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {<StatusChip status={props.variant["status"]} />}
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <br />
          <Typography variant="body2" component="p">
            Created At:
          </Typography>
          <Typography variant="body2" component="p">
            Updated At:
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <br />
          <Typography variant="body2" component="p">
            {formatDate(props.variant["createdAt"])}
          </Typography>
          <Typography variant="body2" component="p">
            {formatDate(props.variant["updatedAt"])}
          </Typography>
        </Grid>
      </Grid>
    </>
    )
  }

  function stepRow(step) {

    var title = "Production Status:";
    var status = props.variant["productionStatus"].toUpperCase();
    var subTitle = "Production Status Updated At:"
    var date = formatDate(props.variant["productionStatusUpdatedAt"]);

    // status = "COMPLETE"

    if (step) {
      title = capitalizeFirstLetter(step) + " Status:";
      status = chipStringFor(props.variant[step + "-status"] || "Pending");

      if (status == "IN PROGRESS") {
        subTitle = capitalizeFirstLetter(step) + " Started At";
        date = formatDate(props.variant[step + "-started_at"]);
      } else if (status == "COMPLETE") {
        subTitle = capitalizeFirstLetter(step) + " Completed At";
        date = formatDate(props.variant[step + "-completed_at"]);
      } else {
        subTitle = "";
        date = "";
      }
    }

    return(
      <>
      <Grid container>
        <Grid item xs={2}>
          <Typography variant="body2" component="p">
              {title}
          </Typography>
          </Grid>
          <Grid item xs={2}>
          {<StatusChip status={status} />}
          </Grid>
          <Grid item xs={3}>
          <Typography variant="body2" component="p">
              {subTitle}
          </Typography>
          </Grid>
          <Grid item xs={3}>
          <Typography variant="body2" component="p">
              {date}
          </Typography>
          </Grid>
      </Grid>
      <Divider className={classes.divider}></Divider>
      </>
    )
  }

  function formatDate(date) {
    let newDate = new Date(date);
      return newDate.toUTCString();
  }

  function chipStringFor(status) {
    var res = status.replace("_", " ");
    return res.toUpperCase();
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
    {variantCard(props.variant, props.index)}
    </>
  );
}
