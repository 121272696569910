import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { Provider as HttpProvider } from 'use-http'
import PrivateRoute from './helpers/PrivateRoute';
import AppLayout from './layouts/AppLayout';
import AuthLayout from './layouts/AuthLayout';

import { AbilityContext } from './context/can';
import { useAuth } from './context/auth';
import { useSnackbar } from './context/snackbar';
import { buildAbilityFor } from './config/ability';

import config from './config';
import './App.scss';

export default function App() {

  const snackbar = useSnackbar();
  const { user, getToken, validateSession } = useAuth();

  const options = {
    interceptors: {
      request: async ({ options, headers }) => {
        const session = await validateSession();
        options.headers['authorization'] = `Bearer ${session.accessToken.jwtToken}`;
        return options;
      },
      response: async ({ response }) => {
        if (!response.ok) {
          console.error('Response Error:', response);
          snackbar.error(`${response.data.message}`);
        }
        return response;
      }
    }
  };

  const ability = buildAbilityFor(user);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      // expose ability to play around with it in devtools
      window.ability = ability;
    }
  }, [ability]);

  return (
    <AbilityContext.Provider value={ability}>
      <HttpProvider url={config.api_endpoint} options={options}>
        <Router>
          <Switch>
            <Route path="/auth" component={AuthLayout} />
            <PrivateRoute path="/app" component={AppLayout} />
            <Route exact path="/"><Redirect to="/app/dashboard" /></Route>
          </Switch>
        </Router>
      </HttpProvider>
    </AbilityContext.Provider>
  );
}
