import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../context/snackbar';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import AddIcon from '@material-ui/icons/Add';
import Can from '../../../context/can';
import useIdVendor from '../../../helpers/IdVendor';
import { DataTable, useDataTable, DataTableContextProvider } from '../../../components/datatable/DataTable';
import ShipmentDialog from './ShipmentDialog';

import { useFetch } from 'use-http';

export default function ShipmentsTable(props) {
  const snackbar = useSnackbar();
  const dataTable = useDataTable();
  const location = new URLSearchParams(useLocation().search);


  const fetch = useFetch('/shipments', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const [showAddShipment, setShowAddShipment] = useState(false);

  let columns = [{
    name: 'Shipment reference',
    key: 'reference',
    renderColumn: (record) => (<Link component={RouterLink} to={`/app/logistics/shipments/${record.id}`}>{record.reference}</Link>)
  }, {
    name: 'Incoming date',
    key: 'incoming_date'
  }, {
    name: 'Last update',
    key: 'updated_at',
    sortable: true
  }];

  // const { request, response } = dataTable;


  const handleAdd = async () => {
    // const id = a8wait getId();
    setShowAddShipment(true);
  };

  const handleAddShipment = () => {
    setShowAddShipment(false);
  };

  function renderToolbar() {
    return (
      <Can I="create" a="shipment">
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={handleAdd}
        >Add</Button>
      </Can>
    );
  }

  return (
    <Paper>
      <DataTableContextProvider value={fetch}>
        <DataTable title="Shipment" columns={columns} toolbar={renderToolbar()} />
        <ShipmentDialog open={showAddShipment} onClose={handleAddShipment} />
      </DataTableContextProvider>
    </Paper>
  );
}
