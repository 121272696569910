import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {DropzoneArea} from 'material-ui-dropzone';

import { useForm, Controller } from 'react-hook-form';
import { useDataTable } from '../../../components/datatable/DataTable';
import useIdVendor from '../../../helpers/IdVendor';
import useFetch from 'use-http';
import ShipmentSelect from '../../logistics/components/ShipmentSelect';

export default function ProductDialog(props) {
  const { register, handleSubmit, reset, control } = useForm();
  const dataTable = useDataTable();
  const { getId } = useIdVendor('purchase_order');


  const handleClose = (event) => {
    props.onClose();
  };


  const onSubmit = async (data) => {
    const id = await getId();

    let purchaseOrder = { id, ...data };
    purchaseOrder.shipment_id = purchaseOrder.shipment.value;
    purchaseOrder.shipment = purchaseOrder.shipment.value;

    console.log('Creating purchase order', purchaseOrder);
    await dataTable.request.post(purchaseOrder);

    if (dataTable.response.ok) {
      reset();
      props.onClose(data);
    }
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Purchase Order</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add a new purchase order
          </DialogContentText>
          <form onSubmit={handleSubmit(onSubmit)}>

            <TextField
              inputRef={register}
              autoFocus
              margin="dense"
              name="reference"
              label="Reference"
              type="string"
              fullWidth
            />
{/*
            <TextField
              inputRef={register}
              autoFocus
              margin="dense"
              name="supplier"
              label="Supplier"
              type="string"
              fullWidth
            /> */}

            <TextField
              inputRef={register}
              autoFocus
              margin="dense"
              name="status"
              label="Status"
              type="string"
              fullWidth
            />

            <TextField
              inputRef={register}
              autoFocus
              margin="dense"
              name="shipment"
              label="Shipment ID"
              type="string"
              fullWidth
            />

            <ShipmentSelect name="shipment" control={control} />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}