import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../context/snackbar';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import useFetch from 'use-http';
import EditForm from '../../components/EditObjectForm';

export default function PurchaseOrderPage(props) {
  const snackbar = useSnackbar();
  const location = new URLSearchParams(useLocation().search);
  const [shipmentID, setShipmentID] = useState("");

  useEffect(() => {
    setShipmentID(props.match.params.shipment_id);
  }, []);

  let form = {
    title : "Purchase Order",
    resource : "/purchase-orders",
    query_slug : props.match.params.po_id,
    return_to : "/app/buying",
    fields : [
      {
        key : "id",
        label: "ID",
        readOnly : true
      },
      {
        key : "reference",
        label : "Reference"
      },
      {
        label: 'Shipment',
        key: 'shipment',
        readOnly : true
      },
      {
        label: 'Status',
        key: 'status'
      },
      {
        key : "issued_date",
        label : "Issued Date"
      },
      {
        key : "updated_at",
        label : "Last Update"
      }
    ],
    address_forms : [
      "buyer", "factory", "supplier"
    ]
  };

  return (
    <Paper>
      <br />
      <EditForm props={form}/>
      <br />
    </Paper>
  );
}
