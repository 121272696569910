import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import AddressForm from '../../../components/AddressForm.js'
import { useForm, Controller } from 'react-hook-form';
import useIdVendor from '../../../helpers/IdVendor';
import useFetch from 'use-http';

export default function LocationDialog(props) {

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();
  const { register, handleSubmit, reset, control, errors } = useForm();

  const [object, setObject] = useState({});
  const [type, setType] = React.useState('');
  const [loaded, setLoaded] = useState(false);
  const [titleText, setTitleText] = useState('');
  const [buttonText, setButtonText] = useState('');

  const {request: dataRequest} = useFetch("/locations");

  useEffect(() => {
    console.log("Props", props);
  }, []);

  const isUpdating = () => {
    return buttonText == 'Save';
  }

  const handleChange = (event) => {
    setType(event.target.value);
    _Change(event);
  };

  function _Change(event){
    let objectCopy = object;
    objectCopy[event.target.name] = event.target.value;
    setObject(objectCopy);

    // console.log("New Object", objectCopy);
  }

  function _ChangeGeo(event){
    let objectCopy = object;

    if (objectCopy.geolocation == null) {
      objectCopy.geolocation = {};
    }

    objectCopy.geolocation[event.target.name] = parseFloat(event.target.value);
    setObject(objectCopy);
  }

  function _ChangeAddress(event){
    let objectCopy = object;

    if (objectCopy.address == null) {
      objectCopy.address = {};
    }

    objectCopy.address[event.target.name] = event.target.value;
    setObject(objectCopy);
  }
  

  const handleClose = (event) => {
    setLoaded(false);
    props.onClose();
  };

  const handleOpen = (event) => {
    setObject({});


    if (props.idForEdit != null) {
      setTitleText("Update location");
      setButtonText("Save");
      getLocation(props.idForEdit);
    } else {
      // Setup blank address and geloaction objects
      let objectCopy = {};
      setTitleText("Create new location");
      setButtonText("Create");

      if (objectCopy.geolocation == null) {
        objectCopy.geolocation = {};
      }

      if (objectCopy.address == null) {
        objectCopy.address = {};
      }

      setObject(objectCopy);

      setLoaded(true);
    }
  };

  const getLocation = async (id) => {
    // Fetch the location to pass to form
    const response = await dataRequest.get(id);
    console.log("Response", response);
    setObject(response);
    setLoaded(true);
  };

  const onSubmit = async (data) => {

    if (isUpdating) {
      const updateLocationResponse = await dataRequest.put(object.id, object);
      console.log("Update Response", updateLocationResponse);
    } else {
      const saveLocationResonse = await dataRequest.post(object);
      console.log("Save Response", saveLocationResonse);
    }
    
    props.onClose(data);
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} onEnter={handleOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{titleText}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A location is the place where the things are
          </DialogContentText>
          {
            loaded&&
            layoutTextFields()
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)} color="primary">
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  function layoutTextFields() {
    return(
      <form onSubmit={handleSubmit(onSubmit)}>

            <TextField
              defaultValue={object.name || ''}
              inputRef={register}
              // autoFocus
              margin="dense"
              name="name"
              label="Location name"
              type="string"
              fullWidth
              onChange={_Change}
            />

            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="type_picker"
              id="type"
              name="type"
              value={object.type ?? ''}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value={"mill"}>Mill</MenuItem>
              <MenuItem value={"factory"}>Factory</MenuItem>
              <MenuItem value={"kitting"}>Kitting</MenuItem>
              <MenuItem value={"distribution"}>Distribution</MenuItem>
              <MenuItem value={"airport"}>Airport</MenuItem>
            </Select>

          <br/><br/><br/>
          <AddressForm title="Address" 
          address={object.address} 
          geolocation={object.geolocation} 
          register={register} 
          _ChangeAddress={_ChangeAddress} 
          _ChangeGeo={_ChangeGeo}/>
            
          </form>
    )
  }
}