import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';

import { useAuth } from '../../../context/auth';
import { useSnackbar } from '../../../context/snackbar';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const { user, completeNewPassword } = useAuth();

  const { register, handleSubmit } = useForm();

  const onSubmit = async (values) => {
    try {
      await completeNewPassword(user, values);
    } catch (error) {
      snackbar.error(error.message);
    }
  };

  return (
    <>
      <Typography component="h1" variant="h5">Set password</Typography>
      <p>Your account needs a new password. Set one below</p>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          inputRef={register}
          autoComplete="current-password"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Set Password
        </Button>
      </form>
    </>
  );
}