import React from 'react';
import { Route, Switch } from "react-router-dom";
import Can from '../../context/can';

import OrdersPage from './OrdersPage';
import OrderPage from './OrderPage';

export default function OrdersRouter({ match }) {
    return (
        <Can I="view" a="contacts">

            <Switch>
                <Route path={`${match.url}/:order_id`} render={(props) => (<OrderPage {...props} />)} />
                <Route path={`${match.url}/`} component={OrdersPage} />
                <Route path={`${match.url}/add`} component={OrdersPage} />
            </Switch>
        </Can>
    );
}