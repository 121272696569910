import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ImageUploadDialog from './ImageUploadDialog';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    }
  }));

export default function AddImagesButton(props) {

    const classes = useStyles();

    const [showAddImage, setShowAddImage] = useState(false);


    const handleAddImage = () => {
        setShowAddImage(false);
    }


    const addImage = () => {
        setShowAddImage(true);
    }

    return (
        <div className={classes.root}>
            <Button
                variant="contained"
                color="primary"
                onClick={() =>addImage()}
                >
                Upload More Images
            </Button>

            <ImageUploadDialog open={showAddImage} onClose={handleAddImage} product={props.product}/>
        </div>
        );
}