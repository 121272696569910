import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  titleAction: {
    textAlign: 'right'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function AddressCard(props) {  

  const addressDisplay = [
    {
      "label" : "Name",
      "key" : "name"
    },
    {
      "label" : "Line 1",
      "key" : "address1"
    },
    {
      "label" : "Line 2",
      "key" : "address2"
    },
    {
      "label" : "City",
      "key" : "city"
    },
    {
      "label" : "Province",
      "key" : "province"
    },
    {
      "label" : "Country",
      "key" : "country"
    },
    {
      "label" : "Post Code",
      "key" : "zip"
    },
    {
      "label" : "Phone",
      "key" : "phone"
    },
    // {
    //   "label" : "Longitude",
    //   "key" : "longitude"
    // },
    // {
    //   "label" : "Latitude",
    //   "key" : "latitude"
    // }
  ]

  function getBillingAddress() {
    if (props.order.shipping_address.name === props.order.billing_address.name &&
      props.order.shipping_address.address1 === props.order.billing_address.address1 &&
      props.order.shipping_address.address2 === props.order.billing_address.address2 &&
      props.order.shipping_address.city === props.order.billing_address.city &&
      props.order.shipping_address.zip === props.order.billing_address.zip &&
      props.order.shipping_address.country === props.order.billing_address.country &&
      props.order.shipping_address.province === props.order.billing_address.province) {
      return(
            <>
            <Grid container>
              <Grid item xs={8}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Billing Address
                </Typography>
                <Typography variant="body2" component="p">
                  Same as shipping address.
                </Typography>
              </Grid>
            </Grid>
            </>
      )
    } else {
      return( 
        addressCard("Billing Address", props.order.billing_address)
      )
    }
  }

  function addressCard(title, address) {
    return ( 
          <>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {title}
              </Typography>
              {addressDisplay.map((item) => (
                <>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="body2" component="p">
                      {item.label + ":" }
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" component="p">
                      {address[item.key]}
                    </Typography>
                  </Grid>
                </Grid>
              </>
              ))}
              </>
    )
  }
  
  const classes = useStyles();
  return (
    <Container >
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={5}>
              {addressCard("Shipping Address", props.order.shipping_address)}
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={5}>
              {getBillingAddress()}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}
