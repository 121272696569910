import React from 'react';
import { Route, Switch } from "react-router-dom";
import Can from '../../context/can';

import DashboardPage from './DashboardPage';

export default function DashboardRouter({ match }) {
    return (
        <Can I="view" a="dashboard">
            <Switch>
                <Route path={`${match.url}/`} component={DashboardPage} />
            </Switch>
        </Can>
    );
}