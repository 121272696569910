import React from 'react';
import Container from '@material-ui/core/Container';
import DocumentsTable from './components/DocumentsTable';

export default function DocumentsPage() {

  return (
      <Container maxWidth={false}>
        <DocumentsTable />
      </Container>
  );
}
