import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// Components
import { Button, Card, CardActions, CardContent, CardHeader, CardMedia } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 445,
        minHeight: 320
    },
    root: {
        width: 230,
    },
    media: {
        height: 320,
        width: '100%',
        paddingTop: '56.25%', // 16:9
    },
    warning: {
        color: theme.palette.warning.main
    }
}));


export default function ProductCard({ product, menuOptions = [] }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const options = [
        ...menuOptions,
        {
            type: 'item',
            label: 'Delete Product',
            icon: (<DeleteIcon fontSize="small" />),
            action: (product) => {
                console.log('Delete product', product);
            }
        }
    ];
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const classes = useStyles();

    function handleMenuAction (option, product) {
        return async () => {
            const result = await option.action(product);
            handleMenuClose();
            return result;

        };
    }

    function handleView() {

    }

    function handleRemove() {

    }

    function renderImages(images) {

        const image = images[Object.keys(images)[0]];

        if (!image) {
            return
        }
        
        // console.log('Render image', image);
        return (
            <CardMedia
                className={classes.media}
                image={image.url}
            />
        );
    }

    function renderEmptyProduct() {
        return (
            <Card>
                Empty
            </Card>
        );
    }

    function renderProduct(product) {
        // console.log('Render product', product);
        return (
            <>
                <Card className={classes.root}>
                    <CardHeader
                        title={product.name}
                        subheader={product.product_sku}
                        avatar={
                            <Avatar aria-label="recipe" className={classes.avatar}>
                                {product.tier[0]}
                            </Avatar>
                        }
                        action={
                            <IconButton aria-label="settings" onClick={handleMenuClick}>
                                <MoreVertIcon />
                            </IconButton>
                        }
                    />
                    {renderImages(product.images)}
                    <CardActions>
                        <Button variant="outlined" size="small" color="primary" onClick={handleView} component={RouterLink} to={`/app/products/${product.id}`}>View</Button>
                        <Button variant="outlined" size="small" color="secondary" className={classes.warning} onClick={handleRemove}>Remove</Button>
                    </CardActions>
                </Card>
                <Menu
                    id="long-menu"
                    className={classes.menuRoot}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                >
                    {options.map((option, index) => (
                        <MenuItem key={index} onClick={handleMenuAction(option, product)}>
                            <ListItemIcon>
                                {option.icon}
                            </ListItemIcon>
                            <Typography variant="inherit">{option.label}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    }

    return product ? renderProduct(product) : renderEmptyProduct();
}