import React from 'react';
import { Route, Switch } from "react-router-dom";
import Can from '../../context/can';
import DocumentsPage from './DocumentsPage';
import { useFetch } from 'use-http';
import { DataTableContextProvider } from '../../components/datatable/DataTable';


export default function DocumentsRouter({ match }) {

  const fetch = useFetch('/documents', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  return (
    <Can I="view" a="documents">
      <DataTableContextProvider value={fetch}>
      <Switch>
        <Route path={`${match.url}/`} component={DocumentsPage} />
        {/* <Route path={`${match.url}/add`} component={BuyingPage} /> */}
      </Switch>
      </DataTableContextProvider>
    </Can>
  );
}