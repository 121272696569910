
import { AbilityBuilder, Ability } from '@casl/ability';


function defineRulesFor(user) {

    const { can, rules } = new AbilityBuilder();

    if (hasRole(user, 'administrators')) {
        can('manage', 'all');
    }

    // Logistics role
    if (hasRole(user, 'members')) {
        can('view', 'dashboard');
        can('view', 'calendar');
        can('view', 'activity');
        can('view', 'locations');
        can('view', 'contacts');
        can('view', 'fulfilment');
        can('view', 'tracking');
        can('view', 'documents');
    }

    return rules;
}

function hasRole(user, role) {
    return (user &&
        user.attributes &&
        user.attributes['cognito:groups'] &&
        user.attributes['cognito:groups'].includes(role)
    );
}

export function buildAbilityFor(user) {
    return new Ability(defineRulesFor(user));
};
