import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { useForm } from 'react-hook-form';
import { useAuth } from '../../../context/auth';
import { useSnackbar }  from '../../../context/snackbar';

const useStyles = makeStyles((theme) => ({

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Email() {
  const classes = useStyles();
  const { signInEmail } = useAuth();
  const snackbar = useSnackbar();
  const { register, handleSubmit } = useForm();

  const onSubmit = async (values) => {
    delete values.remember;

    try {
      const user = await signInEmail(values.username, values.password);
      console.log('Signin User', user);
    } catch (error) {
      snackbar.error(error.message);
    }
  };

  return (<form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      label="Email Address"
      name="username"
      type="input"
      inputRef={register}
      autoComplete="email"
      autoFocus
    />
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      name="password"
      label="Password"
      type="password"
      inputRef={register}
      autoComplete="current-password"
    />
    <FormControlLabel
      control={<Checkbox value="remember" color="primary" />}
      name="remember"
      inputRef={register}
      label="Remember me"
    />
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={classes.submit}
    >
      Sign Up
    </Button>
  </form>)
};