import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../context/snackbar';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import AddIcon from '@material-ui/icons/Add';
import Can from '../../../context/can';
import useIdVendor from '../../../helpers/IdVendor';
import ProductsActionMenu from './ProductsActionMenu';
import { DataTable, useDataTable } from '../../../components/datatable/DataTable';
import ProductForm from './ProductForm';
import ProductDialog from './ProductDialog';

export default function ProductsTable(props) {
  const snackbar = useSnackbar();
  const dataTable = useDataTable();
  const location = new URLSearchParams(useLocation().search);
  const { getId } = useIdVendor('product');

  const [showAddProduct, setShowAddProduct] = useState(false);

  let columns = [{
    name: 'SKU',
    key: 'product_sku',
    renderColumn: (record) => (<Link component={RouterLink} to={`/app/products/${record.id}`}>{record.product_sku}</Link>)
  }, {
    name: 'Status',
    key: 'status'
  }, {
    name: 'Name',
    key: 'name'
  }, {
    name: 'Category',
    key: 'category',
    sortable: true
  }, {
    name: 'Tier',
    key: 'tier',
    sortable: true
  }, {
    name: 'Gender',
    key: 'gender'
  }, {
    name: 'Image',
    key: 'images',
    renderColumn: (record) => {
      const image = record.images[Object.keys(record.images)[0]];

      if (!image) {
        return (<span>No image</span>);
      }

      return (<img src={image.url} width="80"/>);
    }
  }, {
    name: 'Actions',
    key: 'actions',
    renderColumn: (record) => (
      <>
        <Button component={RouterLink} to={`/app/products/${record.id}`}>View</Button>
        <Button onClick={handleDelete(record)}>Delete</Button>
      </>
    )
  }];

  const { request, response } = dataTable;

  const handleAdd = async () => {
    const id = await getId();
    setShowAddProduct(true);
    console.log('Handle Add', id);
  };

  const handleAddProduct = () => {
    setShowAddProduct(false);
  };

  const handleDelete = (item) => {
    return async () => {
      try {
        await request.delete(`/${item.id}`);
        console.log('DELETE Action', item, dataTable);
      } catch (error) {
        console.log('DELETE ERROR', error);
      }

      if (response.ok) {
        const newData = { ...dataTable.data, records: dataTable.data.records.filter(r => r.id !== item.id) }
        dataTable.setData(newData);
        dataTable.fetchData();
        snackbar.success(`Item ${item.id} removed`);
      }
    }
  };

  const handleMultiFilterChange = (key) => {
    return (event, value) => {
      if (value && value.length > 0) {
        dataTable.setFilter(key, value.map(v => v.key));
      } else {
        dataTable.setFilter(key, null);
      }
    }
  };

  // dataTable.data.filters = {
  //   gender: [{ title: 'Womens', key: 'gender', value: 'female' }, { title: 'Mens', key: 'gender', value: 'male' }],
  //   factory: [{ title: 'Design Arc', key: 'factory', value: 'Design Arc' }, { title: 'Incredible Fashion', key: 'factory', value: 'Incredible Fashion' }]
  // };

  function renderFilters() {

    const urlFilters = JSON.parse(location.get('filters')) || [];

    function getUrlFilters(key) {

      if (!urlFilters[key]) {
        return [];
      }

      if (!dataTable.data || !dataTable.data.filters || !dataTable.data.filters[key]) {
        return [];
      }

      return dataTable.data.filters[key].filter(i => urlFilters[key].includes(i.value));
    }

    return (
      dataTable.data.filters && (
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Autocomplete
            multiple
            limitTags={3}
            name="status"
            size="small"
            options={dataTable.data.filters.status || []}
            onChange={handleMultiFilterChange('status')}
            value={getUrlFilters('status')}
            getOptionLabel={(option) => (`${option.key} (${option.count})`)}
            renderInput={(params) => (
              <TextField {...params} name="status" variant="outlined" label="Status" placeholder="Select a status" />
            )}
          />
        </Grid>
      </Grid>)
    );
  }

  function renderToolbar() {
    return (
      <Can I="create" a="product">
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={handleAdd}
        >Add Product</Button>
      </Can>
    );
  }

  return (
    <Paper>
      <DataTable title="Product Tagger" selectable actions={(<ProductsActionMenu />)} showFilters filters={renderFilters()} toolbar={renderToolbar()} columns={columns} />
      <ProductDialog open={showAddProduct} onClose={handleAddProduct} />
    </Paper>
  );
}
