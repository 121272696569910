import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useFetch from 'use-http';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Can from '../../context/can';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabContext, TabPanel } from '@material-ui/lab';
import NotificationsTable from './components/NotificationsTable.js';
import AddressCard from './components/AddressCard';
import VariantCard from './components/VariantCard';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  titleAction: {
    textAlign: 'right'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  tabs: {
    borderBottom: '1px solid #7b8e97'
  }
}));

class OrderVariant {
  constructor(id, 
              orderID, 
              collectionID, 
              isRandom, 
              randomSKU, 
              sku, 
              status, 
              shopifyCustomerID, 
              shopifyID, 
              shopifyOrderID, 
              shopifyProductID,
              createdAt,
              updatedAt,
              productionStatus,
              productionStatusUpdatedAt,
              steps
              ) {
    this.id = id;
    this.orderID = orderID;
    this.collectionID = collectionID;
    this.isRandom = isRandom ? "Yes" : "No";
    this.randomSKU = randomSKU;
    this.sku = sku;
    this.status = status;
    this.shopifyCustomerID = shopifyCustomerID;
    this.shopifyID = shopifyID;
    this.shopifyOrderID = shopifyOrderID;
    this.shopifyProductID = shopifyProductID;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.productionStatus = productionStatus;
    this.productionStatusUpdatedAt = productionStatusUpdatedAt;

    var key;
    for (key in steps) {
      var subkey;
      for (subkey in steps[key]) {
        var variableKey = key + "-" + subkey;
        this[variableKey] = steps[key][subkey];
      }
    }
  }
}

export default function OrderPage() {
  const classes = useStyles();

  const {
    order_id: orderId
  } = useParams();

  const [order, setOrder] = useState();
  const [variants, setVariants] = useState();
  const { request, response } = useFetch(`/orders/${orderId}`);
  const [tab, setTab] = React.useState('order_details');

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    fetchOrder();
    fetchVariants();
  }, []);

  async function fetchOrder() {
    const orderResponse = await request.get();

    if (response.ok) {
      setOrder(orderResponse);
    } else {
      snackbar.error(`Failed to fetch order ${orderID}`);
    }
  }

  async function fetchVariants() {
    const variantsResponse = await request.get('/variants');

    if (response.ok) {
      processVariants(variantsResponse.records);
    } else {
      snackbar.error(`Failed to fetch order variants`);
    }
  }

  function processVariants(variants) {
    var newVariants = [];
    var item;
    for (item in variants) {
      var variant = variants[item];
      var newVariant = new OrderVariant(variant.id, 
                                        variant.order_id,
                                        variant.collection_id, 
                                        variant.is_random,
                                        variant.random_sku,
                                        variant.sku,
                                        variant.status,
                                        variant.shopify_customer_id,
                                        variant.shopify_id,
                                        variant.shopify_order_id,
                                        variant.shopify_product_id,
                                        variant.created_at,
                                        variant.updated_at,
                                        variant.production_status,
                                        variant.production_status_updated_at,
                                        variant.steps
                                        );
      newVariants.push(newVariant);
    }

    setVariants(newVariants);
  }

const orderDisplay = [
  {
    "label" : "Order ID",
    "key" : "id"
  },
  {
    "label" : "Order Status",
    "key" : "status"
  },
  {
    "label" : "Shopify ID",
    "key" : "shopify_id"
  },
  {
    "label" : "Price",
    "key" : "price",
    "subkey" : "value"
  },
  {
    "label" : "Discount",
    "key" : "discount",
    "subkey" : "value"
  },
  {
    "label" : "Shopify Customer ID",
    "key" : "shopify_customer_id"
  },
  {
    "label" : "Placed",
    "key" : "placed_at"
  },
  {
    "label" : "Updated",
    "key" : "updated_at"
  },
  {
    "label" : "Email",
    "key" : "customer_email"
  }
]

const tabStyle = (value) => {
  return ( 
    { color: tab === value ? "#e68231" : "#7b8e97" }
  )
}

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.root}>
        <Grid container direction="row">
          <Grid item xs={8}>
            <Typography variant="h4">Order Overview</Typography>
          </Grid>
        </Grid>
      </div>
      <br/>

      <div className={classes.root}>
      <TabContext value={tab}>
      <div className={classes.tabs}>
        <Tabs value={tab} onChange={handleChange} TabIndicatorProps={{ style: {backgroundColor: "#e68231"}}}>
          <Tab label="Order Details" value="order_details" style={tabStyle("order_details")}/>
          <Tab label="Shipping/Billing" value="addresses" style={tabStyle("addresses")}/>
          <Tab label="Variants" value="variants" style={tabStyle("variants")}/>
          <Tab label="Notifications" value="notifications" style={tabStyle("notifications")}/>
        </Tabs>
        </div>
      <TabPanel value={'order_details'} index={0}>
      {order && orderDetails()}
      </TabPanel>
      <TabPanel value={'addresses'} index={1}>
        <AddressCard order={order} />
      </TabPanel>
      <TabPanel value={'variants'} index={2}>
        {variants && variantCards()}
      </TabPanel>
      <TabPanel value={'notifications'} index={3}>
        <NotificationsTable orderId={orderId} />
      </TabPanel>
        </TabContext>
    </div>      
    </Container>
  );

  function orderDetails() {
    return (
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Order Details
          </Typography>
          {orderDisplay.map((item, i) => (
            <>
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant="body2" component="p">
                    {item.label + ":" }
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" component="p">
                    {orderItemValue(item)}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ))}
        </CardContent>
      </Card>
    )
  }

  function orderItemValue(item) {
    if ("subkey" in item) {
      var value = order[item.key][item.subkey];
      if (item.key == "price" || item.key == "discount") {
        return parseInt(value) / 100;
      } else {
        return value
      }
    } else {
      return order[item.key]
    }
  }

  function variantCards() {
    return (
      <>
        {variants.map((variant, index) => (
          <VariantCard variant={variant} index={index} />
        ))}
        </>
    )
  }
}
