import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../context/snackbar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import Link from '@material-ui/core/Link';
import useIdVendor from '../../../helpers/IdVendor';
import { DataTable, DataTableContextProvider, useDataTable } from '../../../components/datatable/DataTable';
import { useFetch } from 'use-http';
import Axios from 'axios';
import fileDownload from 'js-file-download';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function DocumentsTable({ showFilters }) {
  const snackbar = useSnackbar();
  const dataTable = useDataTable();
  const location = new URLSearchParams(useLocation().search);

  //GET /documents/{id}/download
  // struct {
  //   Bucket string
  //   Key    string
  //   URL    string
  // }
  // get file from value GET of URL

  const detailsFetch = useFetch(`/documents`, {});

  const fetch = useFetch('/documents', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const [percentages, setpercentages] = useState({});

  let columns = [{
    name: 'File name',
    key: 'file_name'
  }, {
    name: 'File type',
    key: 'type'
  }, {
    name: 'Last update',
    key: 'updated_at',
    sortable: true
  }, {
    name: 'Actions',
    key: 'actions',
    renderColumn: renderActions
  },
  // {
  //   key: 'download_progress',
  //   renderColumn: (record) => (progressCircle(record.id))
  // }
];

function renderActions (record) {
  return (
    <>
      {downloadButton(record.id, record.file_name)}
    </>
  )
}

  function downloadButton(fileID, fileName) {
    return (
      <Button onClick={() => handleDownloadFile(fileID, fileName)} >Download</Button>
    );
  }

  function progressCircle(fileID) {
    return (
      <div style={{ width: 50, height: 50 }}>
        <CircularProgressbar value={progressForFile(fileID)} text={`${progressForFile(fileID)}%`} />
      </div>
    );
  };

  function progressForFile(fileID) {
    let percentage = percentages[fileID];
    if (percentage == null) {
      return 0;
    } else {
      return percentage;
    }
  };

  const handleDownloadFile = async (fileId, fileName) => {
    // const detailsResponse = await detailsFetch.get(fileID + "/download");

    const file = await fetch.request.get(`${fileId}/download`);
    fileDownload(file, fileName);

    // Axios.get(detailsResponse.URL, {
    //   responseType: 'blob',
    //   onDownloadProgress: function (progressEvent) {
    //     var percent_complete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
    //     setpercentages({ ...percentages, [fileID]: percent_complete });
    //   },
    // }).then(res => {
    //   fileDownload(res.data, fileName);
    // });
  };

  const handleMultiFilterChange = (key) => {
    return (event, value) => {
      console.log('Value', value)
      if (value && value.length > 0) {
        dataTable.setFilter(key, value.map(v => v.key));
      } else {
        dataTable.setFilter(key, null);
      }
    }
  };

  function renderFilters() {

    const urlFilters = JSON.parse(location.get('filters')) || [];

    function getUrlFilters(key) {

      if (!urlFilters[key]) {
        return [];
      }

      if (!dataTable.data || !dataTable.data.filters || !dataTable.data.filters[key]) {
        return [];
      }

      console.log('Get filter', key, dataTable.data.filters);
      return dataTable.data.filters[key].filter(i => urlFilters[key].includes(i.value));
    }

    return (
      dataTable && dataTable.data && dataTable.data.filters && (<Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            limitTags={2}
            name="type"
            size="small"
            options={dataTable.data.filters.type || []}
            onChange={handleMultiFilterChange('type')}
            value={getUrlFilters('type')}
            getOptionLabel={(option) => (`${option.key} (${option.count})`)}
            renderInput={(params) => (
              <TextField {...params} name="type" variant="outlined" label="Type" placeholder="Select a type" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            limitTags={3}
            name="file_type"
            size="small"
            options={dataTable.data.filters.file_type || []}
            onChange={handleMultiFilterChange('file_type')}
            value={getUrlFilters('file_type')}
            getOptionLabel={(option) => (`${option.key} (${option.count})`)}
            renderInput={(params) => (
              <TextField {...params} name="file_type" variant="outlined" label="File Type" placeholder="Select a file type" />
            )}
          />
        </Grid>
      </Grid>)
    );
  };

  return (
    <Paper>
      <DataTableContextProvider value={fetch}>
        <DataTable columns={columns} showFilters={showFilters} filters={renderFilters()} />
      </DataTableContextProvider>
    </Paper>
  );
}
