import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import GoogleButton from 'react-google-button';

import { useForm } from 'react-hook-form';
import { useAuth } from '../../../context/auth';
import { useSnackbar } from '../../../context/snackbar';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(1, 1)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  google: {
    margin: theme.spacing(2, 1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Email() {
  const classes = useStyles();
  const { signInEmail, signInGoogle } = useAuth();
  const snackbar = useSnackbar();
  const { register, handleSubmit } = useForm();

  const onSubmit = async (values) => {
    delete values.remember;

    try {
      const user = await signInEmail(values.username, values.password);
      console.log('Signin User', user);
    } catch (error) {
      snackbar.error(error.message);
    }
  };

  return (
    <>
      <Typography component="h1" variant="h5">Sign In</Typography>
      <Grid className={classes.google} container spacing={5} justify="center" alignItems="center">
        <Grid item xs={12}>
          <GoogleButton type="light" onClick={() => signInGoogle()} />
        </Grid>
      </Grid>
      <Typography component="h3">Or</Typography>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Email Address"
          name="username"
          type="input"
          inputRef={register}
          autoComplete="email"
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          inputRef={register}
          autoComplete="current-password"
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          name="remember"
          inputRef={register}
          label="Remember me"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Sign In
    </Button>
      </form>
    </>)
};