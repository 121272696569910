import React from 'react';
import { Route, Switch } from "react-router-dom";
import Can from '../../context/can';
import BuyingPage from './LogisticsPage';
import ShipmentPage from './components/ShipmentPage';

export default function BuyingRouter({ match }) {
  return (
    <Can I="view" a="fulfilment">
      <Switch>
        <Route path={`${match.url}/shipments/:shipment_id`} render={(props) => (<ShipmentPage {...props} mode="update" />)} />
        <Route path={`${match.url}/`} component={BuyingPage} />
        <Route path={`${match.url}/add`} component={BuyingPage} />
      </Switch>
    </Can>
  );
}