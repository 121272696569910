import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';

import AddIcon from '@material-ui/icons/Add';
import { Storage } from 'aws-amplify';
import useFetch from 'use-http';
import useIdVendor from '../../helpers/IdVendor';

import PlanningFilesPanel from './components/PlanningFilesPanel';
import PlanningControlPanel from './components/PlanningControlPanel';
import PlanningUploadPanel from './components/PlanningUploadPanel';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function PlanningPage({ match }) {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const { getId } = useIdVendor('document');

  const { request, response } = useFetch('/documents', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const [document, setDocument] = useState({});

  const getDocumentId = async () => {
    const id = await getId();

    setDocument({ ...document, id });
  };

  useEffect(() => {
    getDocumentId();
  }, []);

  const [selectedFiles, setSelectedFiles] = useState();

  const onSubmit = async (data) => {
    console.log('Upload file', data);

    try {
      for (const file of data.files) {
        console.log('File', file);
        const uploadResponse = await Storage.put(`upload/${file.name}`, file);
        console.log('Upload response', uploadResponse);

        const newDocument = {
          ...document,
          type: 'order_update',
          file_name: file.name,
          file_type: file.type,
          source: `upload/${file.name}`,
          size: file.size,
          last_modified: file.lastModifiedDate
        };
        setDocument(newDocument);

        const docResponse = await request.post(newDocument);
        console.log('Doc create', docResponse, document);
      }


    } catch (error) {
      console.error('S3 upload error', error);
    }
  };

  const selectFile = (event) => {
    console.log('Select file', event);
    setSelectedFiles(event.target.value);

    console.log('Selected files', selectedFiles);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={4}>
        <PlanningUploadPanel />
        {/* <PlanningStatsPanel /> */}
      </Grid>
      <Grid item xs={12} md={6} lg={8}>
        <PlanningControlPanel />
      </Grid>
    </Grid>
  </Container>

  );
}
