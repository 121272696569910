import React, { useState, useEffect } from 'react';
import useFetch from 'use-http';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Can from '../../context/can';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Link } from "react-router-dom";
import AddressForm from '../../components/AddressForm.js'
import { useSnackbar } from '../../context/snackbar';
import { useForm, Controller } from 'react-hook-form';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabContext, TabPanel } from '@material-ui/lab';
import PostageURLDialog from './components/PostageURLDialog'
import StatusChip from '../../components/StatusChip.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  titleAction: {
    textAlign: 'right'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  tabs: {
    borderBottom: '1px solid #7b8e97'
  }
}));

export default function SwapsPage() {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const { register, handleSubmit } = useForm();

  const {
    swap_id: swapId
  } = useParams();

  const [swap, setSwap] = useState();
  const [redeemedAt, setRedeemedAt] = useState();
  const [postageURL, setPostageURL] = useState();
  const { request, response } = useFetch(`/swap-tokens/${swapId}`);
  const [tab, setTab] = React.useState('ship_from');
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    fetchSwap();
  }, []);

  const swapDisplay = [
    {
      "label": "Swap ID",
      "key": "id"
    },
    {
      "label": "Swap Status",
      "key": "status"
    },
    {
      "label": "Order ID",
      "key": "order_id"
    },
    {
      "label": "Shopify Order ID",
      "key": "shopify_order_id"
    },
    {
      "label": "Shopify Customer ID",
      "key": "shopify_customer_id"
    },
    {
      "label": "Created",
      "key": "created_at"
    },
    {
      "label": "Updated",
      "key": "updated_at"
    },
    {
      "label": "Expires",
      "key": "expires_at"
    }
  ]

  async function fetchSwap() {
    const swapResponse = await request.get();

    if (response.ok) {
      if (swapResponse.ship_from == null) {
        swapResponse.ship_from = {}
      }

      if (swapResponse.ship_to == null) {
        swapResponse.ship_to = {}
      }

      if (swapResponse.redeemed_at != null) {
        setRedeemedAt(swapResponse.redeemed_at)
      }

      if (swapResponse.rm_postage != null) {
        setPostageURL(swapResponse.rm_postage.url)
      }

      setSwap(swapResponse);
    } else {
      snackbar.error(`Failed to fetch swap ${swapId}`);
    }
  }

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const tabStyle = (value) => {
    return (
      { color: tab === value ? "#e68231" : "#7b8e97" }
    )
  }

  function formatDate(date) {
    let newDate = new Date(date);
    return newDate.toUTCString();
  }

  function valueForSwapKey(key) {

    if (key == 'redeemed_at' &&
      swap[key] == null) {
      return "No"
    }

    if (key == 'created_at' ||
      key == 'updated_at' ||
      key == 'expires_at' ||
      key == 'redeemed_at') {
      return formatDate(swap[key])
    }

    return swap[key]
  }

  function displayForItem(item) {
    if (item.key == "status") {
      return (
        <StatusChip status={swap[item.key]} />
      )
    } else {
      return (
        <>
          <Typography variant="body2" component="p">
            {valueForSwapKey(item.key)}
          </Typography>
        </>
      )
    }
  }

  function _ChangeFromAddress(event) {
    let swapCopy = swap;

    if (swapCopy.ship_from == null) {
      swapCopy.ship_from = {};
    }

    swapCopy.ship_from[event.target.name] = event.target.value;
    setSwap(swapCopy);
  }

  function _ChangeToAddress(event) {
    let swapCopy = swap;

    if (swapCopy.ship_to == null) {
      swapCopy.ship_to = {};
    }

    swapCopy.ship_to[event.target.name] = event.target.value;
    setSwap(swapCopy);
  }

  async function handleRedeemSwapToken() {
    const updateSwapResponse = await request.post('/redeem', {
      id: swapId
    });

    if (response.ok) {
      snackbar.success('Swap redeemed successfully');
      setSwap(updateSwapResponse);
    } else {
      snackbar.error('There was an error redeeming the swap token');
    }
    console.log("Update Response", updateSwapResponse);
  }

  const onSubmit = async (data) => {
    const updateSwapResponse = await request.put(swap);
    console.log("Update Response", updateSwapResponse);
  };

  function saveButton(title) {
    return (
      <>
        <Button type="submit" onClick={handleSubmit(onSubmit)} color="primary" variant="contained">
          {title}
        </Button>
      </>
    )
  }

  function postageDisplay() {
    return (
      <>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant="body2" component="p">
              Postage URL:
                  </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" component="p">
              <a rel="noopener noreferrer" href={postageURL} target="_blank">{postageURL}</a>
            </Typography>
          </Grid>
        </Grid>
      </>
    )
  }

  function redeemedDateDisplay() {
    return (
      <>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant="body2" component="p">
              Redeemed:
                  </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" component="p">
              {formatDate(redeemedAt)}
            </Typography>
          </Grid>
        </Grid>
      </>
    )
  }

  function addPostageButton() {
    return (
      <>
        <Button type="submit" onClick={showPostageURLDialogue} color="primary" variant="contained">
          {postageURL == null ? "Add Postage URL" : "Update Postage URL"}
        </Button>
      </>
    )
  }

  function addRedeemButton() {
    return (
      <>
        <Button type="submit" onClick={handleRedeemSwapToken} color="primary" variant="contained">
          Redeem Token
      </Button>
      </>
    )
  }

  const showPostageURLDialogue = () => {
    setShowDialog(true);
  }

  const handleAddPostageURL = (data) => {
    setShowDialog(false);
    setPostageURL(data.name);
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.root}>
        <Grid container direction="row">
          <Grid item xs={8}>
            <Typography variant="h4">Swap Overview</Typography>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider}></Divider>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {swap && swapDisplay.map((item, i) => (
            <>
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant="body2" component="p">
                    {item.label + ":"}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" component="p">
                    {displayForItem(item)}
                    {/* {valueForSwapKey(item.key)} */}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ))}
          {redeemedAt &&
            redeemedDateDisplay()
          }
          {postageURL &&
            postageDisplay()
          }
        </Grid>
        <Grid container spacing={3}>
          <Grid item>
            {addPostageButton()}
          </Grid>
          <Grid item>
            {addRedeemButton()}
          </Grid>
        </Grid>
      </Grid>
      <br />
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h3>Send To</h3>
          {swap &&
              <>
                <AddressForm
                  address={swap.ship_to}
                  register={register}
                  _ChangeAddress={_ChangeToAddress} />
                <br /><br />
                {saveButton("Save Recipient Address")}
              </>
            }
          </Grid>
          <Grid item xs={6}>
            <h3>Send From</h3>
          {swap &&
              <>
                <AddressForm
                  address={swap.ship_from}
                  register={register}
                  _ChangeAddress={_ChangeFromAddress} />
                <br /><br />
                {saveButton("Save Sender Address")}
              </>
            }
          </Grid>
        </Grid>
        {/* <TabContext value={tab}> */}
          {/* <div className={classes.tabs}>
            <Tabs value={tab} onChange={handleTabChange} TabIndicatorProps={{ style: { backgroundColor: "#e68231" } }}>
              <Tab label="Ship From" value="ship_from" style={tabStyle("ship_from")} />
              <Tab label="Ship To" value="ship_to" style={tabStyle("ship_to")} />
            </Tabs>
          </div> */}
          {/* <TabPanel value={'ship_from'} index={0}> */}

          {/* </TabPanel> */}
          {/* <TabPanel value={'ship_to'} index={1}> */}

          {/* </TabPanel> */}
        {/* </TabContext> */}
      </div>
      <PostageURLDialog open={showDialog} onClose={handleAddPostageURL} swap_id={swapId} />
    </Container>
  );
}
