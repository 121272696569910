import React, { useState, useEffect } from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';

import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import { useAuth } from '../../context/auth';

// Forms
import EmailPasswordForm from './forms/EmailPasswordForm';
import SetupTOTP from './forms/SetupTOTPForm';
import ConfirmSignin from './forms/ConfirmSigninForm';
import NewPassword from './forms/NewPasswordForm';

export default function SignIn() {

  const [signinState, setSigninState] = useState('SIGNIN');

  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      console.log('User', user);
      setSigninState(user.challengeName);
    }
  },  [user]);

  function renderForm(type) {
    console.log('Render form', type);
    switch (type) {
      case 'NEW_PASSWORD_REQUIRED': return (<NewPassword />);
      case 'MFA_SETUP': return (<SetupTOTP />);
      case 'SOFTWARE_TOKEN_MFA': return (<ConfirmSignin />);
      case 'SMS_MFA': return (<ConfirmSignin />);
      case 'SIGNIN': return (<EmailPasswordForm/>);
      default: return (<EmailPasswordForm/>);
    }
  }

  function userHasSession() {
    if (user == null) {
      return false
    }
    
    return user.getSignInUserSession() != null
  }

  return (
    <>
      { userHasSession() && (<Redirect to="/app/dashboard" />)}
      {renderForm(signinState)}
      <Grid container>
        <Grid item xs>
          <Link to="/auth/forgotten" component={RouterLink} variant="body2">
            Forgot password?
          </Link>
        </Grid>
      </Grid>
    </>
  );
}