import React, { useCallback, useContext, createContext, useEffect, useState } from 'react';
import _ from 'lodash';

import { useLocation, useHistory } from 'react-router-dom';

import { useDebounce } from 'use-debounce';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableError from './TableError';
import TableEmpty from './TableEmpty';
import Toolbar from '@material-ui/core/Toolbar';
import FilterIcon from '@material-ui/icons/FilterList';
import ActionIcon from '@material-ui/icons/PlayArrow';

import Title from '../Title';
import SearchBox from './SearchBox';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 40
  },
  title: {
    marginRight: theme.spacing(1)
  },
  filters: {
    padding: theme.spacing(0),
    minWidth: '440px'
  },
  filterContainer: {
    padding: theme.spacing(1)
  },
  toolbar: {
    flexGrow: 1
  },
  toolbarSpacer: {
    flexGrow: 1
  },
  loadingBar: {
    height: '10px'
  },
  button: {
    marginLeft: '10px'
  },
  active: {
    borderColor: theme.palette.info.main,
    color: theme.palette.info.main,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export const DataTableContext = createContext();

function useData(fetch) {
  const history = useHistory();
  const location = new URLSearchParams(useLocation().search);

  const [data, setData] = useState({ total: 0, records: [] });
  const [page, setPage] = useState(parseInt(location.get('page'), 10) || 0);
  const [pageSize] = useState(parseInt(location.get('size'), 10) || 25);
  const [search, setSearch] = useState(location.get('search') || '');
  const [sort, setSort] = useState(location.get('sort') || null);
  const [filters, setFilters] = useState(location.get('filters') ? JSON.parse(location.get('filters')) : null);

  const [selected, setSelected] = useState([]);

  const [debouncedSearch] = useDebounce(search, 400);

  // TODO: Make this configurable
  const uniqueKey = 'id';
  const { request, response } = fetch;

  const fetchData = useCallback(async () => {

    const queryObject = {
      from: page * pageSize,
      size: pageSize,
    };

    if (debouncedSearch) {
      queryObject.search = debouncedSearch;
    }

    if (filters) {
      queryObject.filter = JSON.stringify(filters);
    }

    if (sort) {
      queryObject.sort = JSON.stringify(sort);
    }

    const query = new URLSearchParams(queryObject);

    console.log('Query', query.toString(), filters);
    const newData = await request.get(`?${query.toString()}`);

    if (response.ok) {
      setData(newData);
    }
  }, [filters, page, pageSize, sort, debouncedSearch, request, response]);

  const fetchFilters = useCallback(async () => {
    const newFilters = await request.get(`?limit=1`);

    if (response.ok) {
      setFilters()
    }
  });

  const handleSearch = fetch.handleSearch ? fetch.handleSearch : (term) => {
    setPage(0);
    setSearch(term);

    location.set('page', 0);
    location.set('search', term);
    history.push({ search: location.toString() });
  };

  const handleChangePage = fetch.handleChangePage ? fetch.handleChangePage : (event, page) => {
    setPage(page);

    location.set('page', page);
    history.push({ search: location.toString() });
  };

  const handleRefresh = fetch.handleRefresh ? fetch.handleRefresh : (event) => {
    fetchData();
  };

  const setFilter = (key, filter) => {

    let newFilters = {};

    if (filters) {
      newFilters = Object.assign(newFilters, filters);
    }

    if (!filter) {
      delete newFilters[key];
    } else {
      newFilters[key] = filter;
    }

    setPage(0);
    setFilters(newFilters);

    location.set('page', 0);
    location.set('filters', JSON.stringify(newFilters));
    history.push({ search: location.toString() });

    fetchData();
  };

  const clearFilters = () => {
    setPage(0);
    setFilters({});

    location.set('page', 0);
    location.set('filters', JSON.stringify({}));
    history.push({ search: location.toString() });
  };

  const addSelection = (items) => {

    let newSelected = [...selected];

    console.log('Add selection ', items, newSelected);

    items.forEach(item => {
      newSelected.push(item);
    });

    setSelected(newSelected);
  };

  const addAllToSelection = () => {
    const newSelected = [...selected, data];
    setSelected(newSelected);
  };

  const removeSelection = (items) => {

    let newSelected = [...selected];

    console.log('Remove selection ', items, newSelected);

    items.forEach(item => {
      const filter = {};
      filter[uniqueKey] = item[uniqueKey];
      _.remove(newSelected, filter)
    });

    setSelected(newSelected);
  };

  // const removeSelection = (items) => {

  //   const filter = {};
  //   filter[uniqueKey] = item[uniqueKey];

  //   let newSelected = [...selected];

  //   console.log('Remove selection ', item, filter, newSelected);
  //   _.remove(newSelected, filter);
  //   setSelected(newSelected);
  // };

  const clearSelection = () => {
    setSelected([]);
  };

  const isSelected = (item) => {

    const filter = {};
    filter[uniqueKey] = item[uniqueKey];

    return _.some(selected, filter);
  };

  const isAllSelected = (items) => {
    console.log('All selected?', items, selected);
    return _.every(items, isSelected);
  };

  const isSomeSelected = (items) => {
    console.log('Some selected?', items, selected);
    return _.some(items, isSelected);
  };

  const toggleSelection = (item) => {
    isSelected(item) ? removeSelection([item]) : addSelection([item]);
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize, debouncedSearch, filters, fetchData]);

  return { ...fetch, data, setData, fetchData, filters, setFilter, clearFilters, search, selected, isSelected, isAllSelected, isSomeSelected, toggleSelection, addAllToSelection, addSelection, removeSelection, clearSelection, sort, setSort, page, pageSize, handleSearch, handleChangePage, handleRefresh };
}

export function DataTableContextProvider({ value, children }) {
  const data = useData(value);
  return (<DataTableContext.Provider value={data}>{children}</DataTableContext.Provider>);
}

export function useDataTable() {
  return useContext(DataTableContext);
}

export function DataTable(props) {
  const classes = useStyles();
  const dataTable = useDataTable();

  const uniqueKey = props.key || 'id';

  // Filters
  const [selectedRow, setSelectedRow] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [filtersAnchorEl, setFiltersAnchorEl] = useState(null);

  const handleFiltersToggle = (event) => {
    event.stopPropagation();
    setFiltersAnchorEl(event.currentTarget);
    setShowFilters(!showFilters);
  };

  const handleClickAway = () => {
    setShowFilters(false);
    setShowActionMenu(false);
  };

  const clearFilters = () => {
    dataTable.clearFilters();
    setShowFilters(false);
  };

  const isFilterActive = () => {
    return (dataTable && dataTable.filters && Object.keys(dataTable.filters).length > 0);
  };

  // Actions
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);

  const handleActionMenuToggle = (event) => {
    event.stopPropagation();
    setActionMenuAnchorEl(event.currentTarget);
    setShowActionMenu(!showActionMenu);
  };

  const canPerformAction = () => {
    return dataTable.selected.length > 0;
  };

  const handleActionMenuClose = (event) => {
    console.log('Action menu close');
    setShowActionMenu(false);
  };

  const handleSelectAllToggle = (records) => {
    return (event) => {

      console.log('Toggle all');

      if (isAllItemsSelected(records)) {
        dataTable.removeSelection(records);
      }

      if (!isAllItemsSelected(records) && !isSomeItemsSelected(records)) {
        dataTable.addSelection(records);
      }

      // isItemSelected(records) ? dataTable.clearSelection() : dataTable.addAllToSelection(records);
    };
  };

  const handleSortColumn = (column) => {
    return (event) => {
      dataTable.setSort({ created_at: 'desc' });
      console.log('Sort column', column);
    }
  };

  const isAllItemsSelected = (records) => {
    return dataTable.isAllSelected(records);
  };

  const isSomeItemsSelected = (records) => {
    return dataTable.isSomeSelected(records);
  };

  const isItemSelected = (record) => {
    return dataTable.isSelected(record);
  };

  const toggleSelection = (record) => {
    return (event) => {
      dataTable.toggleSelection(record);
      console.log('Toggle selection', record);
    };
  };

  // const renderCollapseComponent = (props) => prop
  const renderColumnHeader = (column) => {

    if (column.sortable) {
      return (
        <TableCell key={column.key}
          sortDirection={column.sort ? column.sort : false}
        >
          <TableSortLabel
            active={column.sortable}
            direction={column.sort ? column.sort : 'asc'}
            onClick={handleSortColumn(column)}
          >
            {column.name}
            {column.sortable ? (
              <span className={classes.visuallyHidden}>
                {column.sort === 'DESC' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
        </TableCell>);
    }
    return (<TableCell key={column.name}>{column.name}</TableCell>);
  };

  const renderRow = props.renderRow ? props.renderRow : (columns) => {

    // const [isOpen, setIsOpen] = useState(false);

    // const toggleRowCollapse = () => {
    //   setIsOpen(!isOpen);
    // };

    return (record) => {

      return (
        <TableRow key={`${record[uniqueKey]}-summary`}>
          {props.selectable && (
            <TableCell padding="checkbox">
              <Checkbox
                checked={isItemSelected(record)}
                onChange={toggleSelection(record)}
              />
            </TableCell>
          )}
          {columns.map(renderColumn(record))}
        </TableRow>
      );
    };
  };

  const renderColumn = props.renderColumn ? props.renderColumn : (record) => {
    return (column) => (
      <TableCell key={column.key}>
        {
          column.renderColumn ? column.renderColumn(record) : _.get(record, column.key)
        }
      </TableCell>
    );
  };

  return (
    <>
      <Paper>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Toolbar>
            {props.title && (<Title className={classes.title}>{props.title}</Title>)}
            <SearchBox placeholder={`Search ${props.title}`} value={dataTable.search} filter={dataTable.handleSearch} />
            {props.showFilters && (<Box ml={1}>
              <Button
                variant="outlined"
                className={isFilterActive() ? classes.active : ''}
                endIcon={<FilterIcon />}
                onClick={handleFiltersToggle}
              >Filters</Button>
              <Popper open={showFilters} anchorEl={filtersAnchorEl} className={classes.filters}>
                <Paper className={classes.filters}>
                  <Toolbar>
                    <Typography>Filters</Typography>
                    <div className={classes.toolbarSpacer}></div>
                    <Button onClick={clearFilters} variant="outlined">Clear all</Button>
                  </Toolbar>
                  <Container className={classes.filterContainer}>
                    {props.filters}
                  </Container>
                </Paper>
              </Popper>
            </Box>
            )}
            <Box ml={1}>
              <Button
                variant="outlined"
                className={showActionMenu ? classes.active : ''}
                endIcon={<ActionIcon />}
                disabled={!canPerformAction()}
                onClick={handleActionMenuToggle}
                aria-controls="action-menu" aria-haspopup="true"
              >Actions ({dataTable.selected.length})</Button>
              <Menu
                id="action-menu"
                anchorEl={actionMenuAnchorEl}
                keepMounted
                open={showActionMenu}
                onClose={handleActionMenuClose}
              >
                <MenuItem>Test</MenuItem>
                {props.actions}
              </Menu>
            </Box>
            <div className={classes.toolbarSpacer}></div>
            {props.toolbar}
          </Toolbar>
        </ClickAwayListener>
        <div className={classes.loadingBar} >{dataTable.loading && (<LinearProgress />)}</div>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {props.selectable && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={isSomeItemsSelected(dataTable.data.records) && !isAllItemsSelected(dataTable.data.records)}
                      checked={isAllItemsSelected(dataTable.data.records)}
                      onChange={handleSelectAllToggle(dataTable.data.records)}
                      inputProps={{ 'aria-label': 'select all ' }}
                    />
                  </TableCell>
                )}
                {props.columns && props.columns.map(renderColumnHeader)}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.data && dataTable.data.records && dataTable.data.records.map(renderRow(props.columns))}
            </TableBody>
          </Table>
        </TableContainer >
        {dataTable.error && (<TableError error={dataTable.error} response={dataTable.errorData} retry={dataTable.handleRefresh} />)}
        {dataTable.data && !dataTable.data.records && <TableEmpty message="There are no records to show" />}
        {dataTable.data && dataTable.data.total > 0 && (
          <TablePagination
            component="div"
            rowsPerPageOptions={[10]}
            count={dataTable.data.total}
            rowsPerPage={dataTable.pageSize}
            page={dataTable.page}
            onChangePage={dataTable.handleChangePage}
          />
        )}
      </Paper >
    </>
  );
}
