/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:ab0c244f-4ed9-4cd7-b201-38990194844b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_6GD8iK4Gc",
    "aws_user_pools_web_client_id": "4ji9ubbutkvjmu070d8p5gq4d3",
    "oauth": {
        "domain": "ufuiadmin88f8a260-88f8a260-production.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://admin.staging.thisisunfolded.com/auth/signin/,http://localhost:3000/auth/signin/,https://admin.thisisunfolded.com/auth/signin/",
        "redirectSignOut": "https://admin.staging.thisisunfolded.com/auth/signout/,http://localhost:3000/auth/signout/,https://admin.thisisunfolded.com/auth/signout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "004110863328-uf-api-documents-store02504-production",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
