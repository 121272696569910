import React from 'react';
import { Route, Switch } from "react-router-dom";
import Can from '../../context/can';
import CollectionsPage from './CollectionsPage';
import CollectionPage from './CollectionPage';

export default function CollectionsRouter({ match }) {
  return (
    <Can I="view" a="collections">
      <Switch>
        <Route path={`${match.url}/:collection_id/:collection_view`} render={(props) => (<CollectionPage {...props} mode="update" />)} />
        <Route path={`${match.url}/:collection_id`} render={(props) => (<CollectionPage {...props} mode="update" />)} />
        <Route path={`${match.url}/`} component={CollectionsPage} />
        <Route path={`${match.url}/add`} render={(props) => (<CollectionPage {...props} mode="create" />)} />
      </Switch>
    </Can>
  );
}