import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { useAuth } from '../context/auth';

import Copyright from '../components/Copyright';

import Forgotten from '../pages/auth/Forgotten';
import ForgottenSubmit from '../pages/auth/ForgottenSubmit';
import SignIn from '../pages/auth/SignIn';

import { ReactComponent as Logo } from '../icons/unfolded-logo-black.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  logo: {
    width: '200px',
    margin: theme.spacing(0, 0, 6, 0),
    fill: 'black'
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    backgroundImage: 'url(../images/signin.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }
}));

export default function AuthLayout({ match }) {
  const classes = useStyles();
  const { user } = useAuth();

  function userHasSession() {
    if (user == null) {
      return false
    }

    return user.getSignInUserSession() != null
  }

  return (
    userHasSession() ?
      (<Redirect to="/app/dashboard" />) : (
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.paper}>
              <Logo className={classes.logo} />
              <Switch>
                <Route exact path={`${match.url}/signin`} component={SignIn} />
                <Route exact path={`${match.url}/signout`} component={SignIn} />
                <Route exact path={`${match.url}/forgotten/submit`} component={ForgottenSubmit} />
                <Route exact path={`${match.url}/forgotten`} component={Forgotten} />
              </Switch>
              <Box mt={5}>
                <Copyright />
              </Box>
            </div>
          </Grid>
        </Grid>
      )
  );
}
