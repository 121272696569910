import useFetch from 'use-http';

export default function useIdVendor(resource) {

  const { request, response } = useFetch('/id-vendor', {
    cachePolicy: 'no-cache'
  });

  const getIds = async (count = 1) => {

    const ids = await request.get(`?resource=${resource}&count=${count}`);

    if (response.ok) {
      return ids;
    } else {
      console.error('Could not generate ids', response.error);
      throw new Error('ID could not be generated');
    }
  };

  const getId = async () => {
    const ids = await getIds(1);
    return ids.ids[0];
  };

  return { getIds, getId };
}