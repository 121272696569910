import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../context/snackbar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { DataTable, useDataTable, DataTableContextProvider } from '../../../components/datatable/DataTable';


import { useFetch } from 'use-http';

export default function LocationsTable(props) {
  const snackbar = useSnackbar();
  const dataTable = useDataTable();
  const location = new URLSearchParams(useLocation().search);


  const fetch = useFetch('/locations', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });
  
  let columns = [{
    name: 'Name',
    key: 'name'
  }, {
    name: 'Type',
    key: 'type'
  }, {
    name: 'Address',
    key: 'address.address1'
  }, {
    name: 'Actions',
    key: 'actions',
    renderColumn: (record) => ([<Button
                                variant="contained"
                                onClick={ () => handleEdit(record.id)}
                              >Edit</Button>, <Button
                              variant="contained"
                              onClick={ () => handleDelete(record.id)}
                            >Delete</Button>])
  }
];

  const handleEdit = (id) => {
    console.log("Edit location with id", id);
    props.handleEdit(id);
  };

  const handleDelete = (id) => {
    console.log("Delete location with id", id);
    props.handleDelete(id);
  };

  return (
    <Paper>
      <DataTableContextProvider value={fetch}>
        <DataTable title="Locations" columns={columns} />
      </DataTableContextProvider>
    </Paper>
  );
}
