import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { useDataTable } from '../../../components/datatable/DataTable';
import Title from '../../../components/Title';
import { Grid } from '@material-ui/core';
import { Controller, useForm, useWatch } from 'react-hook-form';
import useFetch from 'use-http';
import useIdVendor from '../../../helpers/IdVendor';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 160
  }
}));

export default function CollectionDetailsForm({ collection, onSuccess }) {
  const classes = useStyles();
  const tasklist = useDataTable();
  const { getId: getFulfilmentId } = useIdVendor('fulfilment_task');

  const initialMetadata = {
    S3_BUCKET: 'ls-api-documents-store',
    ASSIGNMENT_TYPE: "planned",
    ASSIGNMENT_INDEX: "0",
    BUILD_PLAN_INDEX: "0",
    BUILD_WAVE: "0",
    BOX_SKU: '',
    BATCH_SIZE: "0",
    LIMIT_SKUS: false,
    RETURN_SKUS: false
  };

  const [metadata, setMetadata] = useState(initialMetadata);
  console.log('Collection form', collection);
  const { register, control, errors, handleSubmit } = useForm({ defaultValues: collection });

  const onSubmit = async (data) => {
    console.log('Collection form submit', data);
    onSuccess(data);
  };

  return (
    <Container fixed>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              inputRef={register}
              autoFocus
              margin="dense"
              name="name"
              label="Collection name"
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register}
              autoFocus
              margin="dense"
              name="sku"
              label="SKU"
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register}
              autoFocus
              margin="dense"
              name="unit_price_value"
              label="Price"
              type="number"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="type">Price currency</InputLabel>
              <Controller
                as={
                  <Select labelId="type" label="Price currency">
                    <MenuItem value="gbp" selected>GBP</MenuItem>
                  </Select>
                }
                name="unit_price_currency"
                defaultValue="gbp"
                control={control}
              />
              <FormHelperText>
                {errors.type && <span>This field is required</span>}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="type">Department</InputLabel>
              <Controller
                as={
                  <Select labelId="type" label="Department">
                    <MenuItem value="womens" selected>Womens</MenuItem>
                    <MenuItem value="mens">Mens</MenuItem>
                    <MenuItem value="girls">Girls</MenuItem>
                    <MenuItem value="boys">Boys</MenuItem>
                  </Select>
                }
                name="department"
                defaultValue="womens"
                control={control}
              />
              <FormHelperText>
                {errors.type && <span>This field is required</span>}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="type">Season</InputLabel>
              <Controller
                as={
                  <Select labelId="type" label="Season">
                    <MenuItem value="" disabled>-- Select season --</MenuItem>
                    <MenuItem value="spring">Spring</MenuItem>
                    <MenuItem value="summer">Summer</MenuItem>
                    <MenuItem value="autumn">Autumn</MenuItem>
                    <MenuItem value="winter">Winter</MenuItem>
                  </Select>
                }
                name="season"
                defaultValue=""
                control={control}
              />
              <FormHelperText>
                {errors.type && <span>This field is required</span>}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit">Save</Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}