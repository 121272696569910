import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { useAuth } from '../../context/auth';
import { useSnackbar } from '../../context/snackbar';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



const ForgottenForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const snackbar = useSnackbar();
  const { forgotPasswordSubmit } = useAuth();

  const { register, handleSubmit } = useForm();

  const onSubmit = async (values) => {
    delete values.remember;

    try {
      console.log("Values", values);
      const user = await forgotPasswordSubmit(values);
      console.log({ user });
      history.push(`/app/dashboard`);
    } catch (error) {
      console.log('Signin error', error);
      snackbar.error(error.message);
    }
  };

  return (
    <>
      <Typography component="h1" variant="h5">Reset password</Typography>
      <p>You forgot your password. Enter your new password and the verification code that was emailed to you below</p>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Email Address"
          name="username"
          type="input"
          inputRef={register}
          autoComplete="email"
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="New Password"
          type="password"
          inputRef={register}
          autoComplete="current-password"
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="code"
          label="Verification Code"
          type="input"
          inputRef={register}
          autoComplete="current-password"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Reset Password
        </Button>
      </form>
    </>
  );
};

export default function SignIn() {

  return (
    <>
      {ForgottenForm()}
      <Grid container>
        <Grid item xs>
          <Link to="/auth/signin" component={RouterLink} variant="body2">
            Remembered your password? Sign In
          </Link>
        </Grid>
      </Grid>
    </>
  );
}