import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Can } from './context/can';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CollectionsIcon from '@material-ui/icons/Collections';
import ProductsIcon from '@material-ui/icons/Wc';
import LocationIcon from '@material-ui/icons/LocationOn';
import ContactIcon from '@material-ui/icons/PermContactCalendar';
import SwapIcon from '@material-ui/icons/SwapHoriz';

import OrdersIcon from '@material-ui/icons/Receipt';

export const mainListItems = (
  <div>
    <Can I="view" a="dashboard">
      <ListItem button component={RouterLink} to={`/app/dashboard`}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
    </Can>

    <Can I="view" a="collections">
      <ListItem button component={RouterLink} to={`/app/collections`}>
        <ListItemIcon>
          <CollectionsIcon />
        </ListItemIcon>
        <ListItemText primary="Collections" />
      </ListItem>
    </Can>

    <Can I="view" a="products">
      <ListItem button component={RouterLink} to={`/app/products`}>
        <ListItemIcon>
          <ProductsIcon />
        </ListItemIcon>
        <ListItemText primary="Products" />
      </ListItem>
    </Can>

    <Can I="view" a="locations">
      <ListItem button component={RouterLink} to={`/app/locations`}>
        <ListItemIcon>
          <LocationIcon />
        </ListItemIcon>
        <ListItemText primary="Locations" />
      </ListItem>
    </Can>

    <Can I="view" a="contacts">
      <ListItem button component={RouterLink} to={`/app/contacts`}>
        <ListItemIcon>
          <ContactIcon />
        </ListItemIcon>
        <ListItemText primary="Contacts" />
      </ListItem>
    </Can>

    <Can I="view" a="orders">
      <ListItem button component={RouterLink} to={`/app/orders`}>
        <ListItemIcon>
          <OrdersIcon />
        </ListItemIcon>
        <ListItemText primary="Orders" />
      </ListItem>
    </Can>

    <Can I="view" a="swap">
      <ListItem button component={RouterLink} to={`/app/swap?page=0&filters=%7B"status"%3A"REQUEST"%7D`}>
        <ListItemIcon>
          <SwapIcon />
        </ListItemIcon>
        <ListItemText primary="Swap" />
      </ListItem>
    </Can>
  </div>
);
