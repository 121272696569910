import Amplify from 'aws-amplify';
import AmplifyConfig from './aws-exports';

const dev = {
    api_endpoint: 'https://api.staging.thisisunfolded.com',
    aws_project_region: "eu-west-1",
    aws_cognito_identity_pool_id: "eu-west-1:002bc382-51fd-4921-b02f-dbe979fe7324",
    aws_cognito_region: "eu-west-1",
    aws_user_pools_id: "eu-west-1_CIkmybXZN",
    aws_user_pools_web_client_id: "619hpm97mvna379biakpjrsuod",
    oauth: {
        domain: "auth.staging.thisisunfolded.com",
        scope: [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: "http://localhost:3000/auth/signin/",
        redirectSignOut: "http://localhost:3000/auth/signout/",
        responseType: "code"
    },
    Storage: {
        AWSS3: {
            bucket: '944949891969-uf-api-documents-store', //REQUIRED -  Amazon S3 bucket
            region: 'eu-west-1', //OPTIONAL -  Amazon service region
        }
    }

};


const staging = {
    api_endpoint: 'https://api.staging.thisisunfolded.com',
    aws_project_region: "eu-west-1",
    aws_cognito_identity_pool_id: "eu-west-1:002bc382-51fd-4921-b02f-dbe979fe7324",
    aws_cognito_region: "eu-west-1",
    aws_user_pools_id: "eu-west-1_CIkmybXZN",
    aws_user_pools_web_client_id: "619hpm97mvna379biakpjrsuod",
    oauth: {
        domain: "auth.staging.thisisunfolded.com",
        scope: [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: "https://admin.staging.thisisunfolded.com/auth/signin/",
        redirectSignOut: "https://admin.staging.thisisunfolded.com/auth/signout/",
        responseType: "code"
    },
    Storage: {
        AWSS3: {
            bucket: '944949891969-uf-api-documents-store', //REQUIRED -  Amazon S3 bucket
            region: 'eu-west-1', //OPTIONAL -  Amazon service region
        }
    }

};

const production = {
    api_endpoint: 'https://api.thisisunfolded.com',
    aws_project_region: "eu-west-1",
    aws_cognito_identity_pool_id: "eu-west-1:ab0c244f-4ed9-4cd7-b201-38990194844b",
    aws_cognito_region: "eu-west-1",
    aws_user_pools_id: "eu-west-1_6GD8iK4Gc",
    aws_user_pools_web_client_id: "4ji9ubbutkvjmu070d8p5gq4d3",
    oauth: {
        domain: "auth.thisisunfolded.com",
        scope: [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: "https://admin.thisisunfolded.com/auth/signin/",
        redirectSignOut: "https://admin.thisisunfolded.com/auth/signout/",
        responseType: "code"
    },
    Storage: {
        AWSS3: {
            bucket: '004110863328-uf-api-documents-store', //REQUIRED -  Amazon S3 bucket
            region: 'eu-west-1', //OPTIONAL -  Amazon service region
        }
    }

};

let config = dev;

switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'production': config = production; break;
    case 'staging': config = staging; break;
    case 'dev': config = dev; break;
    default: config = dev;
}

AmplifyConfig.aws_project_region= config.aws_project_region;
AmplifyConfig.aws_cognito_identity_pool_id = config.aws_cognito_identity_pool_id;
AmplifyConfig.aws_cognito_region = config.aws_cognito_region;
AmplifyConfig.aws_user_pools_id = config.aws_user_pools_id;
AmplifyConfig.aws_user_pools_web_client_id = config.aws_user_pools_web_client_id;
AmplifyConfig.aws_user_files_s3_bucket = config.Storage.AWSS3.bucket;
AmplifyConfig.aws_user_files_s3_bucket_region = config.Storage.AWSS3.region;
AmplifyConfig.oauth = config.oauth;
AmplifyConfig.Storage = config.Storage;
console.log('Amplify Config', AmplifyConfig);
Amplify.configure(AmplifyConfig);

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    API_URL: config.api_url,
    ...config
};