import React, { useState } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { DropzoneArea } from 'material-ui-dropzone';
import { Storage } from 'aws-amplify';
import useFetch from 'use-http';
import useIdVendor from '../../../helpers/IdVendor';

export default function PlanningUploadPanel() {
  const { getId } = useIdVendor('document');
  const { getId: getFulfilmentId } = useIdVendor('fulfilment_task');
  const { getId: getFileID } = useIdVendor('tmp_object');

  const { response, request } = useFetch('/documents', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const { request: fulfilmentRequest } = useFetch('/fulfilment', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const [files, setFiles] = useState();

  const handleFileChange = (newFiles) => {
    console.log('Files:', newFiles);
    setFiles(newFiles);
  };

  const handleFileUpload = (options) => {

    return async (event) => {
      console.log('Upload files', files);

      for (const file of files) {
        const uploadResponse = await Storage.put(`upload/${await getFileID()}`, file);

        console.log('Upload response', uploadResponse);

        const newDocument = {
          id: await getId(),
          type: options.documentType,
          file_name: file.name,
          file_type: file.type,
          source: `public/${uploadResponse.key}`,
          size: file.size,
          last_modified: file.lastModifiedDate
        };

        console.log('Registering new document', newDocument, file);
        const docResponse = await request.post(newDocument);
        console.log('Doc create', docResponse);

        if (response.ok) {

          const task = {
            id: await getFulfilmentId(),
            type: options.taskType,
            metadata: Object.assign({
              S3_BUCKET: docResponse.bucket,
              S3_KEY: docResponse.key,
              BUILD_PLAN_KEY: docResponse.key
            }, options.metadata || {})
          }

          const taskResponse = await fulfilmentRequest.post('/tasks/run', task);

        } else {
          console.log('Problem uploading and registering the document', response);
        }
      }
    }
  };

  return (
    <Card>
      <Typography variant="h6">Upload Build Plan</Typography>
      <CardContent>
        <DropzoneArea
          dropzoneText="Select a Planning file to upload"
          showFileNames
          filesLimit={1}
          alertSnackbarProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }}
          acceptedFiles={['.csv']}
          onChange={handleFileChange}
        />
      </CardContent>
      <CardActions>
        <Button type="submit" onClick={handleFileUpload({
          documentType: 'planning:build_plan',
          taskType: 'fulfilment:build-plan-process',
          metadata: {
            BOX_SKU: '',
            ASSIGNMENT_TYPE: 'planned',
            ASSIGNMENT_INDEX: '0',
            BUILD_PLAN_INDEX: '0',
            BUILD_WAVE: '0',
            BATCH_SIZE: '0',
            LIMIT_SKUS: 'false',
            RETURN_SKUS: 'false',
            SHOPIFY_SHOP: 'mallzee',
            TASK_TYPE: 'warehouse'
          }
        })}>Upload</Button>
      </CardActions>
    </Card>
  );
}