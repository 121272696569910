import React from 'react';
import { Route, Switch } from "react-router-dom";
import Can from '../../context/can';

import ContactPage from './ContactsPage';

export default function ContactRouter({ match }) {
    return (
        <Can I="view" a="contacts">

            <Switch>
                <Route path={`${match.url}/`} component={ContactPage} />
                <Route path={`${match.url}/add`} component={ContactPage} />
            </Switch>
        </Can>
    );
}