import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function JobTable({ props, rows }) {

  const [selectedTaskType, setselectedTaskType] = useState("");
  const [open, setOpen] = useState(false);
  const [tasks, setTasks] = useState([]);

  const { request: fulfilmentRequest } = useFetch('/fulfilment', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  const handleChange = (event) => {
    setselectedTaskType(event.target.value);
    fetchList(event.target.value)
  };

  useEffect(() => {
    
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const fetchList = async (taskType) => {
    // POST /fulfilment/tasks/list <JSON Body { "type": "shopify:tagger" } > To list tasks by their type
    const typeBody = {
      type: taskType
    }
    const listResponse = await fulfilmentRequest.post('/tasks/list', typeBody)
    setTasks(listResponse.records.slice(0, 5));
  };

  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <br></br>
        Choose a task type below to see details of recently performed tasks
        <InputLabel>Task type</InputLabel>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={selectedTaskType}
          onChange={handleChange}
        >
          <MenuItem value="import:build-plan">Build Plan Status</MenuItem>
          <MenuItem value="assignments:assign">Assignment Status</MenuItem>
          <MenuItem value="import:boxstatus">Build Status</MenuItem>
          <MenuItem value='import:stock-levels'>Stock Status</MenuItem>
          <MenuItem value='shopify:fulfilment'>Fulfilment</MenuItem>
          <MenuItem value='shopify:tagger'>Order Tags</MenuItem>
        </Select>


        <Grid container spacing={3}>
          {tasks.map((task) => (
            <Grid item xs={12} md={6} lg={6}>
              <Card>
                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow key={task.id}>
                        <TableCell>{task.id}</TableCell>
                        <TableCell/>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Started At</TableCell>
                        <TableCell component="th" scope="row">
                          {task.created_at}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Updated At</TableCell>
                        <TableCell component="th" scope="row">
                          {task.updated_at}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell component="th" scope="row">
                          {task.status}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Error</TableCell>
                        <TableCell component="th" scope="row">
                          {task.error}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Button variant="contained" 
                            className={classes.button}
                            component={RouterLink} 
                            to={`/app/fulfilment/${task.id}`}>
                              View Logs
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          ))}
        </Grid>
        <br /><br/>
      </CardContent>
    </Card>
  );
}