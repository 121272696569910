import React, { forwardRef, useState, useEffect } from 'react';

import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ProductInventoryDialog from './ProductInventoryDialog';

// Hooks
import { useSnackbar } from '../../../context/snackbar';
import useFetch from 'use-http';
import useIdVendor from '../../../helpers/IdVendor';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function ProductVariantsTable({ productId, productSku, shopifyProductId }) {

  const { getId } = useIdVendor('variant');
  const { request, response } = useFetch(`/products/${productId}/variants`);
  const snackbar = useSnackbar();

  const [showInventoryDialog, setShowInventoryDialog] = useState(false);
  const [inventory, setInventory] = useState();
  const [variants, setVariants] = useState([]);

  const options = {
    paging: false,
    actionsColumnIndex: -1,
    sorting: true
  };

  const columns = [
    { title: 'Product ID', field: 'product_id', hidden: true, initialEditValue: productId },
    { title: 'Size', field: 'size', customSort: (a, b) => a.size - b.size },
    { title: 'SKU', field: 'sku', initialEditValue: productSku },
    { title: 'Shopify Product ID', field: 'shopify_product_id', type: 'numeric', initialEditValue: shopifyProductId },
    { title: 'Shopify ID', field: 'shopify_id', type: 'numeric' },
    { title: 'Shopify Inventory ID', field: 'shopify_inventory_id', type: 'numeric' },
    { title: 'Quantity', field: 'quantity', type: 'numeric' },
    { title: 'Weight', field: 'unit_weight.value', type: 'numeric' },
    { title: 'Weight Unit', field: 'unit_weight.unit', initialEditValue: 'kg', lookup: { kg: 'kg' }},
    { title: 'RRP', field: 'rrp_cost.value', type: 'numeric' },
    { title: 'RRP Currency', field: 'rrp_cost.currency', initialEditValue: 'GBP', lookup: { GBP: 'GBP' } },
    { title: 'Unit Cost', field: 'unit_cost.value', type: 'numeric' },
    { title: 'Unit Currency', field: 'unit_cost.currency', initialEditValue: 'GBP', lookup: { GBP: 'GBP' } },
    // { title: 'Created at', field: 'created_at', type: 'date' }
  ];

  useEffect(() => {
    fetchVariants();
  }, []);

  const fetchVariants = async () => {
    const variantsResponse = await request.get();
    setVariants(variantsResponse.records);
  }

  const editable = {
    onRowAdd: handleVariantAdd,
    onRowUpdate: handleVariantUpdate,
    onRowDelete: handleVariantDelete
  };

  const actions = [{
    icon: tableIcons.Add,
    tooltip: 'Adjust quantity',
    onClick: (event, variant) => {
      console.log('TODO: quantity adjustment');
      setInventory({
        id: variant.id,
        sku: variant.sku,
        inventory_item_id: variant.shopify_inventory_id,
        location_id: 58403946676,
      });
      setShowInventoryDialog(true);
    }
  }];

  async function handleVariantAdd(variant) {
    console.log('Add', variant);
    const id = await getId();
    console.log('Variant id', id);

    variant.id = id;
    await request.post(variant)

    if (response.ok) {
      snackbar.success(`Variant ${variant.id} saved`);

      setTimeout(() => {
        setVariants([...variants, variant]);
      }, 500);

    } else {
      snackbar.error(`Error saving variants ${variant.id}`)
    }
  }

  async function handleVariantUpdate(variant) {
    await request.put(variant.id, variant)

    if (response.ok) {
      snackbar.success(`Variant ${variant.sku} saved`);
    } else {
      snackbar.error(`Error saving variants ${variant.id}`)
    }
  }

  async function handleVariantDelete(variant) {

    console.log('Delete variant', variant);
    await request.delete(variant.id);

    if (response.ok) {
      snackbar.success(`Variant ${variant.sku} deleted`);

      setTimeout(() => {
        const dataDelete = [...variants];
        const index = variant.tableData.id;
        dataDelete.splice(index, 1);
        setVariants([...dataDelete]);
      }, 500);
    } else {
      snackbar.error(`Error deleteing variant ${variant.id}`);
    }
  }

  async function handleInventoryAdd(inventory) {
    console.log('Inventory', inventory);

    await request.post(`${inventory.id}/inventory`, inventory)
    setShowInventoryDialog(false);
  }

  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        options={options}
        editable={editable}
        actions={actions}
        columns={columns}
        data={variants}
        icons={tableIcons}
        title="Variants"
      />
      <ProductInventoryDialog open={showInventoryDialog} inventory={inventory} onClose={handleInventoryAdd} />
    </div>
  )
}