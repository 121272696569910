import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import { useForm, Controller } from 'react-hook-form';
import useIdVendor from '../../../helpers/IdVendor';
import useFetch from 'use-http';

export default function ContactDialog(props) {

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();
  const { register, handleSubmit, reset, control, errors } = useForm();

  const [object, setObject] = useState({});
  const [type, setType] = React.useState('');
  const [loaded, setLoaded] = useState(false);
  const [titleText, setTitleText] = useState('');
  const [buttonText, setButtonText] = useState('');

  const {request: dataRequest} = useFetch("/contacts");

  useEffect(() => {
    console.log("Props", props);
  }, []);

  function isUpdating() {
    console.log("Button text", buttonText);
    return buttonText == 'Save';
  }

  const handleChange = (event) => {
    setType(event.target.value);
    _Change(event);
  };

  function _Change(event){
    let objectCopy = object;
    objectCopy[event.target.name] = event.target.value;
    setObject(objectCopy);

    // console.log("New Object", objectCopy);
  }
  
  const handleClose = (event) => {
    setLoaded(false);
    props.onClose();
  };

  const handleOpen = (event) => {
    setObject({});


    if (props.idForEdit != null) {
      setTitleText("Update contact");
      setButtonText("Save");
      getContact(props.idForEdit);
    } else {
      // Setup blank address and geloaction objects
      let objectCopy = {};
      setTitleText("Create new contact");
      setButtonText("Create");

      setObject(objectCopy);

      setLoaded(true);
    }
  };

  const getContact = async (id) => {
    // Fetch the location to pass to form
    const response = await dataRequest.get(id);
    console.log("Response", response);
    setObject(response);
    setLoaded(true);
  };

  const onSubmit = async (data) => {

    if (isUpdating()) {
      const updateContactResponse = await dataRequest.put(object.id, object);
      console.log("Update Response", updateContactResponse);
    } else {
      const saveContactResonse = await dataRequest.post(object);
      console.log("Save Response", saveContactResonse);
    }
    
    props.onClose(data);
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} onEnter={handleOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{titleText}</DialogTitle>
        <DialogContent>
          {
            loaded&&
            layoutTextFields()
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)} color="primary">
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  // type Request struct {
  //   Title          string `json:"title" validate:"required"`
  //   FirstName      string `json:"first_name" validate:"required"`
  //   LastName       string `json:"last_name" validate:"required"`
  //   Email          string `json:"email"`
  //   PhoneNumber    string `json:"phone_number"`
  //   PhoneNumberAlt string `json:"phone_number_alt"`
  //   JobTitle       string `json:"job_title"`
  //   JobRole        string `json:"job_role"`
  //   Description    string `json:"description"`
  // }

  function layoutTextFields() {
    return(
      <form onSubmit={handleSubmit(onSubmit)}>

<InputLabel id="demo-simple-select-label">Title</InputLabel>
            <Select
              labelId="type_picker"
              id="title"
              name="title"
              value={object.title ?? ''}
              onChange={handleChange}
              fullWidth
            > 
              <MenuItem value={"Mr"}>Mr</MenuItem>
              <MenuItem value={"Mrs"}>Mrs</MenuItem>
              <MenuItem value={"Ms"}>Ms</MenuItem>
              <MenuItem value={"Miss"}>Miss</MenuItem>
              <MenuItem value={"Madam"}>Madam</MenuItem>
              <MenuItem value={"Dr"}>Dr</MenuItem>
              <MenuItem value={"Rev"}>Rev</MenuItem>
              <MenuItem value={"Lady"}>Lady</MenuItem>
              <MenuItem value={"Lord"}>Lord</MenuItem>
              <MenuItem value={"Sir"}>Sir</MenuItem>
              <MenuItem value={"Captain"}>Captain</MenuItem>
              <MenuItem value={"General"}>General</MenuItem>
              <MenuItem value={"Major"}>Major</MenuItem>
            </Select>

            <TextField
              defaultValue={object.first_name || ''}
              inputRef={register}
              // autoFocus
              margin="dense"
              name="first_name"
              label="First name"
              type="string"
              fullWidth
              onChange={_Change}
            />

            <TextField
              defaultValue={object.last_name || ''}
              inputRef={register}
              // autoFocus
              margin="dense"
              name="last_name"
              label="Last name"
              type="string"
              fullWidth
              onChange={_Change}
            />

            <TextField
              defaultValue={object.email || ''}
              inputRef={register}
              // autoFocus
              margin="dense"
              name="email"
              label="Email"
              type="string"
              fullWidth
              onChange={_Change}
            />

            <TextField
              defaultValue={object.phone_number || ''}
              inputRef={register}
              // autoFocus
              margin="dense"
              name="phone_number"
              label="Phone Number"
              type="string"
              fullWidth
              onChange={_Change}
            />

            <TextField
              defaultValue={object.phone_number_alt || ''}
              inputRef={register}
              // autoFocus
              margin="dense"
              name="phone_number_alt"
              label="Phone Number Alt"
              type="string"
              fullWidth
              onChange={_Change}
            />

            <TextField
              defaultValue={object.job_title || ''}
              inputRef={register}
              // autoFocus
              margin="dense"
              name="job_title"
              label="Job Title"
              type="string"
              fullWidth
              onChange={_Change}
            />

            <InputLabel id="demo-simple-select-label">Job Role</InputLabel>
            <Select
              labelId="type_picker"
              id="job_role"
              name="job_role"
              value={object.job_role ?? ''}
              onChange={handleChange}
              fullWidth
            >
              // Roles to be defined
              <MenuItem value={"role1"}>Role 1</MenuItem>
              <MenuItem value={"role2"}>Role 2</MenuItem>
            </Select>

            <TextField
              defaultValue={object.description || ''}
              inputRef={register}
              // autoFocus
              margin="dense"
              name="description"
              label="Description"
              type="string"
              fullWidth
              onChange={_Change}
            />
            
          </form>
    )
  }
}