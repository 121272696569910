import React from 'react';
import { Route, Switch } from "react-router-dom";
import Can from '../../context/can';

import SwapsPage from './SwapsPage';
import SwapPage from './SwapPage';

export default function SwapRouter({ match }) {
    return (
        <Can I="view" a="swap">

            <Switch>
                <Route path={`${match.url}/:swap_id`} render={(props) => (<SwapPage {...props} />)} />
                <Route path={`${match.url}/`} component={SwapsPage} />
            </Switch>
        </Can>
    );
}