import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { useSnackbar } from '../../../context/snackbar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { DataTable, useDataTable, DataTableContextProvider } from '../../../components/datatable/DataTable';
import StatusChip from '../../../components/StatusChip.js';

import { useFetch } from 'use-http';

export default function OrdersTable(props) {
  const snackbar = useSnackbar();
  const dataTable = useDataTable();
  const location = new URLSearchParams(useLocation().search);


  const fetch = useFetch('/orders', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });
  
  let columns = [{
      name: 'Order ID',
      key: 'id',
      renderColumn: (record) => (<Link component={RouterLink} to={`/app/orders/${record.id}`}>{record.id}</Link>)

    },
    { 
      name: 'Status',
      key: 'status',
      renderColumn: (record) => (<StatusChip status={record.status} />)
    },
    { 
      name: 'Customer Email',
      key: 'customer_email'
    },
    { 
      name: 'Customer Shopify ID',
      key: 'shopify_customer_id'
    },
    { 
      name: 'Placed At',
      key: 'placed_at'
    }
  ];

  return (
    <Paper>
      <DataTableContextProvider value={fetch}>
        <DataTable title="Orders" columns={columns} />
      </DataTableContextProvider>
    </Paper>
  );
}
