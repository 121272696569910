import React from 'react';
import { Route, Switch } from "react-router-dom";

import useFetch from 'use-http';

import Can from '../../context/can';
import { DataTableContextProvider } from '../../components/datatable/DataTable';
import PlanningPage from './PlanningPage';

export default function PlanningRouter({ match }) {

  const fetch = useFetch('/documents', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  return (
    <Can I="view" a="planning">
      <DataTableContextProvider value={fetch}>
        <Switch>
          <Route path={`${match.url}/`} component={PlanningPage} />
        </Switch>
      </DataTableContextProvider>
    </Can>
  );
}