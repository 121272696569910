import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Can from '../../context/can';

import CollectionsTable from './components/CollectionsTable';
import CollectionDialog from './components/CollectionDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  titleAction: {
    textAlign: 'right'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function FulfilmentPage({ match }) {
  const classes = useStyles();

  const [showAddCollection, setShowAddCollection] = useState(false);

  const handleAddCollection = () => {
    setShowAddCollection(false);
  };

  const handleAdd = async () => {
    // const id = a8wait getId();
    setShowAddCollection(true);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.root}>
        <Grid container direction="row">
          <Grid item xs={8}>
            <Typography variant="h4">Collections</Typography>
            {/* <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/" onClick={handleClick}>Collections</Link>
            </Breadcrumbs> */}
          </Grid>
          <Grid item xs={4} className={classes.titleAction}>
            <Can I="create" a="collection">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={handleAdd}
              >New collection</Button>
            </Can>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider}></Divider>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CollectionsTable />
          <CollectionDialog open={showAddCollection} onClose={handleAddCollection} />
        </Grid>
      </Grid>
    </Container>
  );
}
