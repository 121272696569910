import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { Controller, useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 160
  }
}));

export default function TIUSelect({
  name,
  label,
  emptyLabel = 'Select',
  options,
  children,
  control,
  errors,
  rules,
  defaultValue,
  ...props
}) {

  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} error={!!errors[name]} {...props}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Controller
        name={name}
        as={(
          <Select labelId={name} label={label} {...props}>
            {options && options.map((item) => (
              <MenuItem key={item.value} value={item.value} selected={item.selected}>{item.label}</MenuItem>
            ))}
          </Select>
        )}
        control={control}
        rules={rules}
        defaultValue={defaultValue ? defaultValue : ''}
      />
      <FormHelperText>
        {errors[name] && <span>{errors[name].message}</span>}
      </FormHelperText>
    </FormControl>
  )
}