import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import CollectionDetailsForm from './components/CollectionDetailsForm';
import CollectionProductionTimeline from './components/CollectionProductionTimeline';
import CollectionTransportView from './components/CollectionTransportView';
import DocumentsTable from '../documents/components/DocumentsTable';
import ProductsGrid from '../products/components/ProductsGrid';
import ProductDialog from '../products/components/ProductDialog';

import Can from '../../context/can';
import { useFetch } from 'use-http';
import { TabContext, TabPanel } from '@material-ui/lab';
import useIdVendor from '../../helpers/IdVendor';
import { useSnackbar } from '../../context/snackbar';
import { MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // marginBottom: theme.spacing(2),
    width: '100%'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  tabView: {
    flexGrow: 1,
    display: 'flex'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabLabel: {
    alignItems: 'flex-start'
  },
  tabPanel: {
    width: '100%'
    // flexGrow: 1
  },
  titleAction: {
    textAlign: 'right'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function CollectionPage({ match }) {
  const classes = useStyles();
  const handleClick = () => { };
  const snackbar = useSnackbar();
  const {
    collection_id: collectionId,
    collection_view: collectionView
  } = useParams();

  const { getId: getProductId } = useIdVendor('product');

  const [showAddProduct, setShowAddProduct] = useState(false);

  const handleAddProduct = () => {
    setShowAddProduct(false);
  };


  const [collection, setCollection] = useState({});
  const { request, response } = useFetch(`/collections/${collectionId}`);

  const fetchCollection = async () => {
    const collection = await request.get();

    console.log('Collection response', response, request);
    if (response.ok) {
      console.log('Setting collection', request);
      setCollection(collection);
    }
  }


  const saveCollection = async (collection) => {
    collection.images = [];
    collection.products = [];

    try {

      await request.put(collection.id, collection);

      if (response.ok) {
        snackbar.success(`Collection ${collection.name} created`);
      }
    } catch (error) {
      snackbar.error(error.message);
    }
  };

  const [tab, setTab] = React.useState(collectionView || 'details');
  const history = useHistory();

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
    history.push(`/app/collections/${collectionId}/${newTab}`);
  };
  console.log('Dessign', match.url);

  const handleDetailsSubmit = async (data) => {
    console.log('Submitting collection data', data);
    setCollection(data);
    await saveCollection(data);
  };

  const handleAddDocument = async () => {
    // const id = a8wait getId();
    // setShowAddCollection(true);
  };

  function showAddCollectionProduct() {
    setShowAddProduct(true);
  }

  function handleTimelineChange(collection) {
    setCollection(collection);
  }


  const productMenuOptions = [{
    type: 'item',
    label: 'Remove from collection',
    icon: (<RemoveIcon fontSize="small" />),
    action: handleRemoveCollectionProduct
  }];

  async function handleAddCollectionProduct(product) {
    await request.put('/products', {
      action: 'add',
      products: [product.id]
    });

    if (response.ok) {
      snackbar.success(`Product ${product.product_sku} added to collection`)
    }


    setShowAddProduct(false);
  };

  useEffect(() => {
    fetchCollection();
  }, []);

  async function handleRemoveCollectionProduct(product) {
    await request.put('/products', {
      action: 'remove',
      products: [product.id]
    });

    if (response.ok) {
      snackbar.success(`Product ${product.product_sku} removed from collection`)
    }


    setShowAddProduct(false);
  };

  useEffect(() => {
    fetchCollection();
  }, []);

  const productIDs = () => {
    return(collection.products.join('","'));
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.root}>
        <Grid container direction="row">
          <Grid item xs={8}>
            <Typography variant="h4">Collections</Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/" component={RouterLink} to="/app/collections">Collections</Link>
              <Typography color="textPrimary">{collection && collection.name}</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={4} className={classes.titleAction}>

          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider}></Divider>
      <div className={classes.tabView}>
        <TabContext value={tab}>

          <Tabs
            value={tab}
            orientation="vertical"
            variant="scrollable"
            onChange={handleTabChange}
            aria-label="Collections tabs"
            className={classes.tabs}>
            <Tab label="Details" value="details" classes={{ wrapper: classes.tabLabel }} />
            <Tab label="Documents" value="documents" classes={{ wrapper: classes.tabLabel }} />
            <Tab label="Products" value="products" classes={{ wrapper: classes.tabLabel }} />
            <Tab label="Feedback" value="feedback" classes={{ wrapper: classes.tabLabel }} />
            <Tab label="Production" value="production" classes={{ wrapper: classes.tabLabel }} />
            <Tab label="Logistics" value="logistics" classes={{ wrapper: classes.tabLabel }} />
          </Tabs>
          {/* </AppBar> */}
          <TabPanel value={'details'} index={0} className={classes.tabPanel}>
            <Container fixed className={classes.divder}>
              <Grid container direction="row">
                <Grid item xs={8}>
                  <Typography variant="h4">Details</Typography>
                </Grid>
              </Grid>
            </Container>
            <CollectionDetailsForm collection={collection} onSuccess={handleDetailsSubmit} />
          </TabPanel>
          <TabPanel value={'documents'} index={1} className={classes.tabPanel}>
            <Container fixed className={classes.root}>
              <Grid container direction="row">
                <Grid item xs={8}>
                  <Typography variant="h4">Documents</Typography>
                </Grid>
                <Grid item xs={4} className={classes.titleAction}>
                  <Can I="create" a="collection">
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<AddIcon />}
                      onClick={handleAddDocument}
                    >New document</Button>
                  </Can>
                </Grid>
              </Grid>
              <DocumentsTable />
            </Container>
          </TabPanel>
          <TabPanel value={'products'} index={2} className={classes.tabPanel}>
            <Container fixed className={classes.root}>
              <Grid container spacing={2} direction="row">
                <Grid item xs={8}>
                  <Typography variant="h4">Products</Typography>
                </Grid>
                <Grid item xs={4} className={classes.titleAction}>
                  <Can I="create" a="collection">
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<AddIcon />}
                      onClick={showAddCollectionProduct}
                    >New product</Button>
                  </Can>
                </Grid>
              </Grid>
              {collection.products &&
              <ProductsGrid productMenuOptions={productMenuOptions} productIDs={productIDs} />
}
            </Container>
          </TabPanel>
          <TabPanel value={'feedback'} index={3} className={classes.tabPanel}>
            <div className={classes.root}>
              <Grid container direction="row">
                <Grid item xs={8}>
                  <Typography variant="h4">Feedback</Typography>
                </Grid>
                <Grid item xs={4} className={classes.titleAction}>
                  <Can I="create" a="collection">
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<AddIcon />}
                      onClick={handleAddDocument}
                    >New document</Button>
                  </Can>
                </Grid>
              </Grid>
            </div>
            <ProductsGrid productMenuOptions={productMenuOptions} />
          </TabPanel>
          <TabPanel value={'production'} index={4} className={classes.tabPanel}>
            <div className={classes.root}>
              <Grid container direction="row">
                <Grid item xs={8}>
                  <Typography variant="h4">Production</Typography>
                </Grid>
                <Grid item xs={4} className={classes.titleAction}>
                </Grid>
              </Grid>
            </div>
            {collection && <CollectionProductionTimeline collection={collection} onChange={handleTimelineChange} />}
          </TabPanel>
          <TabPanel value={'logistics'} index={5} className={classes.tabPanel}>
            <div className={classes.root}>
              <Grid container direction="row">
                <Grid item xs={8}>
                  <Typography variant="h4">Logistics</Typography>
                </Grid>
                <Grid item xs={4} className={classes.titleAction}>
                  <Can I="create" a="collection">
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<AddIcon />}
                      onClick={handleAddDocument}
                    >New document</Button>
                  </Can>
                </Grid>
              </Grid>
            </div>
            <ProductsGrid />
            <CollectionTransportView />
          </TabPanel>
        </TabContext>
      </div>
      <ProductDialog open={showAddProduct} onClose={handleAddCollectionProduct} />
    </Container>
  );
}
