import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { useDataTable } from '../../../components/datatable/DataTable';
import Title from '../../../components/Title';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function TrackingStatsPanel() {
  const classes = useStyles();
  const tasklist = useDataTable();

  const [rows, setRows] = useState([]);

  async function getTasks() {

    const tasks = await tasklist.request.post({
      "type": "import:tracking"
    });

    setRows(tasks.records);

    console.log('Tasks', tasks);
  }

  useEffect(getTasks, []);


  // const rows = [{
  //   name: 'Tracking codes sent',
  //   value: '42046'
  // }, {
  //   name: 'Packages delivered',
  //   value: '42046'
  // }, {
  //   name: 'Last tracking update',
  //   value: '2020-08-05 09:00:00'
  // }];

  return (
    <Paper>
      <Title>Tracking Statistics</Title>
    <TableContainer>
      <Table className={classes.table} aria-label="tracking statistics">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell align="right">{row.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
          </Paper>
  )
}