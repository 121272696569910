import React, { createContext, useContext, useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import JWTDecode from 'jwt-decode';

function useAuthApi() {
  const [user, setUser] = useState(null);
  const [ready, setReady] = useState(false);

  async function getCurrentUser() {

    let currentUser, currentToken;

    try {
      currentUser = await Auth.currentAuthenticatedUser();
      currentToken = currentUser.getSignInUserSession().getIdToken().getJwtToken();

      if (currentToken) {
        const userAttributes = JWTDecode(currentToken);
        currentUser.attributes = userAttributes;
      }

      setUser(currentUser);
    } catch (error) {
      console.log('No current user');
    }

    console.log('User', currentUser);
    setReady(true);
  }

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log('Hub listen', event, data);
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          console.log('User signed in');
          getCurrentUser();
          break;
        case 'signOut':
          console.log('User signed out');
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        default:
          console.log('Unknown_case');
      }
    });

    getCurrentUser();
  }, []);

  return {
    completeNewPassword: (user, options) => {
      console.log('Complete password', user, options);
      return Auth.completeNewPassword(user, options.password);
    },
    forgottenPassword: (options) => {
      console.log('Forgotten', options);
      return Auth.forgotPassword(options.username);
    },
    forgotPasswordSubmit: (options) => {
      return Auth.forgotPasswordSubmit(options.username, options.code, options.password);
    },
    setupTotp: (user) => {
      return Auth.setupTOTP(user);
    },
    setPreferredMFA: (user, options) => {
      return Auth.setPreferredMFA(user, options.method);
    },
    verifyTotpToken: (user, options) => {
      return Auth.verifyTotpToken(user, options.code)
    },
    signOut: () => {
      return Auth.signOut({ global: true });
    },
    signInEmail: async (username, password) => {
      const signinUser = await Auth.signIn(username, password);
      // const settings = await Auth.setPreferredMFA(signinUser, 'TOTP');
      setUser(signinUser);
      return signinUser;
    },
    signInGoogle: () => {
      return Auth.federatedSignIn({ provider: 'Google' });
    },
    getToken: () => {
      if (user) {
        if (user.getSignInUserSession()) {
          return user.getSignInUserSession().getIdToken().getJwtToken();
        }
      }
      return false;
    },
    validateSession: () => {
      return Auth.currentSession();
    },
    user,
    ready
  };
};

export const AuthContext = createContext();

export function AuthContextProvider({ children }) {
  const auth = useAuthApi();
  return (auth.ready && <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>);
}

export function useAuth() {
  return useContext(AuthContext);
}
