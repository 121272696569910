import React from 'react';

import { DataTable, useDataTable } from '../../../components/datatable/DataTable';

export default function TrackingFilesTables() {
  const dataTable = useDataTable();

  const columns = [
    {
      name: 'Created At',
      key: 'created_at'
    }, {
      name: 'Name',
      key: 'file_name'
    }, {
      name: '# Total',
      key: 'total'
    }
  ];

  return (
    <DataTable title="Tracking Files" columns={columns} />
  )
}