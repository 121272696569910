import React from 'react';
import { Route, Switch } from "react-router-dom";
import Can from '../../context/can';
import StockPage from './StockPage';
import { useFetch } from 'use-http';
import { DataTableContextProvider } from '../../components/datatable/DataTable';


export default function StockRouter({ match }) {

  const fetch = useFetch('/analytics/stock/sku_status', {
    perPage: 10,
    cachePolicy: 'no-cache'
  });

  return (
    <Can I="view" a="stock">
      <DataTableContextProvider value={fetch}>
      <Switch>
        <Route path={`${match.url}/`} component={StockPage} />
      </Switch>
      </DataTableContextProvider>
    </Can>
  );
}