import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useFetch } from 'use-http';
import { Link as RouterLink } from 'react-router-dom';
import Axios from 'axios';
import fileDownload from 'js-file-download';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { TableFooter } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function BuildManifestFilesTable({ files }) {

  const classes = useStyles();  

  const [percentages, setpercentages] = useState({});
  const detailsFetch = useFetch(`/documents`, {});

  const handleDownloadFile = async (fileID, fileName) => {
    const detailsResponse = await detailsFetch.get(fileID + "/download");

      Axios.get(detailsResponse.URL, {
        responseType: 'blob',
        onDownloadProgress: function (progressEvent) {
          var percent_complete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setpercentages({...percentages, [fileID] : percent_complete});
        },
      }).then(res => {
        fileDownload(res.data, fileName);
      });
  };

  function progressCircle(fileID) {
    return (
      <div style={{ width: 50, height: 50 }}>
        <CircularProgressbar value={progressForFile(fileID)} text={`${progressForFile(fileID)}%`} />
      </div>
    );
  };

  function progressForFile(fileID) {
    let percentage = percentages[fileID];
    if (percentage == null) {
      return 0;
    } else {
      return percentage;
    }
  };

  return (
    <Container>
      <Card>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Build Manifest Files</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Action</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((file) => (
                <TableRow key={file.file_name}>
                  <TableCell component="th" scope="row">
                    {file.file_name || ''} 
                  </TableCell>
                  <TableCell>
                    {file.created_at || ''} 
                  </TableCell>
                  <TableCell>
                    <Button 
                      variant="contained" 
                      className={classes.button}
                      onClick={() => { handleDownloadFile(file.id, file.file_name) }}>
                        Download
                    </Button>
                  </TableCell>
                  <TableCell>
                  {progressCircle(file.id)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>
                  <Button variant="contained" 
                        className={classes.button}
                        component={RouterLink} 
                        to={`/app/documents`}>
                    View All Files
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Card>
    </Container>
  );
}