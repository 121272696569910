import React from 'react';
import { Route, Switch } from "react-router-dom";
import Can from '../../context/can';

import LocationsPage from './LocationsPage';

export default function LocationRouter({ match }) {
    return (
        <Can I="view" a="locations">

    <Switch>
        <Route path={`${match.url}/`} component={LocationsPage} />
        <Route path={`${match.url}/add`} component={LocationsPage} />
    </Switch>
        </Can>
    );
}