import React from 'react';
import { Route, Switch } from "react-router-dom";
import Can from '../../context/can';

import ProductsPage from './ProductsPage';
import ProductPage from './ProductPage';

export default function ProductsRouter({ match }) {

  return (
    <Can I="view" a="products">
      <Switch>
        <Route path={`${match.url}/add`} render={(props) => (<ProductPage {...props} mode="create" />)} />
        <Route path={`${match.url}/:product_id`} render={(props) => (<ProductPage {...props} mode="update" />)} />
        <Route path={`${match.url}/`} render={(props) => (<ProductsPage />)} />
      </Switch>
    </Can>
  );
}